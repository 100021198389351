import { Inject, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

import { EUserType, IAuthenticationResult, IInternalUser, USER_PRIVILEGES, Validation } from '@caronsale/cos-models';
import { CosCoreClient } from '@caronsale/frontend-services';
import { AppEnvironment, IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { EnzoDialogService } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';

export const ADMIN_TWO_FACTOR_ACTIVATION_ROUTE = '/admin/two-factor-authentication';
export const INTERNAL_TWO_FACTOR_ACTIVATION_PAGE = '/internal/kam/two-factor-authentication';

// TODO: Move authentication logic from CarOnSaleClient here
@Injectable({
  providedIn: 'root',
})
export class CosAuthService {
  public redirectUrl = '';
  private expectedPrivileges: string[] = [];
  private currentAuthentication: IAuthenticationResult;
  public internalUser: IInternalUser;
  public isUserMfaActive: boolean;

  public getCurrentAuthentication(): IAuthenticationResult {
    return this.currentAuthentication;
  }

  public setCurrentAuthentication(val: IAuthenticationResult) {
    this.currentAuthentication = val;
  }

  public constructor(
    private router: Router,
    private cosCoreClient: CosCoreClient,
    private enzoDialogService: EnzoDialogService,
    @Inject(AppEnvironment) private environment: IAppEnvironment,
  ) {}

  public saveUrlAndGoToLogin(url: string, expectedPrivileges: string[] = []): Promise<boolean> {
    this.redirectUrl = url;
    this.expectedPrivileges = expectedPrivileges;
    return this.router.navigateByUrl('/login');
  }

  public getUserTypeFromRedirectUrl(): EUserType | null {
    const expectedPrivileges = this.expectedPrivileges;
    if (!expectedPrivileges || expectedPrivileges.length === 0) {
      return null;
    }
    if (expectedPrivileges.includes(USER_PRIVILEGES.SALESMAN_USER)) {
      return EUserType.SALESMAN;
    }
    if (expectedPrivileges.includes(USER_PRIVILEGES.DEALERSHIP_USER) || expectedPrivileges.includes(USER_PRIVILEGES.DEALERSHIP_SUPERVISOR_USER)) {
      return EUserType.DEALERSHIP;
    }
    return null;
  }

  public goToHomePath(authenticationResponse: IAuthenticationResult): Promise<boolean> {
    return this.resetAndNavigateTo(this.getHomePath(authenticationResponse));
  }

  public goToRedirectUrlOrHomePath(authenticationResponse: IAuthenticationResult, mfaPreference?: { totpEnabled: boolean }): Promise<boolean> {
    if (Validation.isUndefinedNullOrEmptyString(this.redirectUrl)) {
      return this.resetAndNavigateTo(this.getHomePath(authenticationResponse, mfaPreference));
    }
    return this.resetAndNavigateTo(this.redirectUrl);
  }

  private resetAndNavigateTo(targetPath: string): Promise<boolean> {
    this.redirectUrl = null;
    this.expectedPrivileges = [];
    return this.router.navigateByUrl(targetPath);
  }

  public getHomePath(authenticationResponse: IAuthenticationResult, mfaPreference?: { totpEnabled: boolean }): string {
    const userPrivileges = authenticationResponse.privileges;
    switch (authenticationResponse.type) {
      case EUserType.DEALERSHIP: // Seller
        // If supervisor, start with supervisor dashboard
        if (null !== userPrivileges.match(USER_PRIVILEGES.DEALERSHIP_SUPERVISOR_USER)) {
          return '/dealership-supervisor';
        }
        return '/dealership';

      case EUserType.SALESMAN: // Salesman
        return '/salesman/cockpit';

      case EUserType.INTERNAL: // Internal
        // ENBL-298 - Change CRM First page to 2FA Activation Page
        const isTotpEnabled = mfaPreference && mfaPreference.totpEnabled === true;
        if (userPrivileges.match(USER_PRIVILEGES.SYSADMIN_USER)) {
          return isTotpEnabled === false && this.environment.production ? `${ADMIN_TWO_FACTOR_ACTIVATION_ROUTE}?origin=login` : '/admin/dashboard';
        }
        if (userPrivileges.match(USER_PRIVILEGES.INTERNAL_USER)) {
          return isTotpEnabled === false && this.environment.production ? `${INTERNAL_TWO_FACTOR_ACTIVATION_PAGE}?origin=login` : '/internal/kam/auctions';
        }
        return '';

      default:
        return '';
    }
  }

  public persistInternalUser(internalUser: IInternalUser): void {
    this.internalUser = internalUser;
    localStorage.setItem('internalUser', JSON.stringify(internalUser));
  }

  public persistUserMfaStatus(isUserMfaActive: boolean): void {
    this.isUserMfaActive = isUserMfaActive;
    localStorage.setItem('isUserMfaActive', JSON.stringify(isUserMfaActive));
  }

  public getInternalUser(): IInternalUser | null {
    if (!this.internalUser) {
      try {
        this.internalUser = JSON.parse(localStorage.getItem('internalUser'));
      } catch (_error) {
        this.internalUser = null;
      }
    }
    return this.internalUser;
  }

  public getUserMfaActive(): boolean | null {
    if (!this.isUserMfaActive) {
      try {
        this.isUserMfaActive = JSON.parse(localStorage.getItem('isUserMfaActive'));
      } catch (_error) {
        this.isUserMfaActive = null;
      }
    }
    return this.isUserMfaActive;
  }

  public twoFactorAuthGuard(): true | Observable<UrlTree> {
    if (!this.getUserMfaActive() && this.environment.production) {
      return this.enzoDialogService.openOkModal('crm.two-factor.messages.obligation', 'danger').pipe(
        switchMap(() => {
          const currentAuthentication = this.cosCoreClient.getLastAuthenticationResult();
          const userPrivileges = currentAuthentication.privileges;

          switch (currentAuthentication.type) {
            case EUserType.INTERNAL: // Internal
              this.redirectUrl = null;
              this.expectedPrivileges = [];

              if (userPrivileges.match(USER_PRIVILEGES.SYSADMIN_USER)) {
                return of(this.router.createUrlTree([ADMIN_TWO_FACTOR_ACTIVATION_ROUTE]));
              }
              if (userPrivileges.match(USER_PRIVILEGES.INTERNAL_USER)) {
                return of(this.router.createUrlTree([INTERNAL_TWO_FACTOR_ACTIVATION_PAGE]));
              }
          }
        }),
      );
    }

    return true;
  }

  public reset() {
    this.redirectUrl = null;
    this.expectedPrivileges = [];
    this.currentAuthentication = null;
    this.internalUser = null;
    this.isUserMfaActive = null;
    localStorage.removeItem('internalUser');
    localStorage.removeItem('isUserMfaActive');
  }
}
