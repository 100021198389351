import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { I18nGeneralPipesModule } from '@caronsale/frontend-pipes';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { AdditionalTaxInfoComponent } from './additional-tax-info.component';
import { I18nAdditionalTaxTypePipe } from '@cos/partials/additional-tax-info/i18n-additional-tax-type.pipe';

@NgModule({
  imports: [
    //
    BaseModule,
    CommonModule,
    I18nGeneralPipesModule,
  ],
  declarations: [
    //
    AdditionalTaxInfoComponent,
    I18nAdditionalTaxTypePipe,
  ],
  exports: [
    //
    AdditionalTaxInfoComponent,
    I18nAdditionalTaxTypePipe,
  ],
})
export class AdditionalTaxInfoModule {}
