import { Injectable } from '@angular/core';
import { EPublicEnvironmentConfigKeys, IServiceMetaData } from '@caronsale/cos-models';
import { CosCoreClient } from '@caronsale/frontend-services';
import { forkJoin, map, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private appConfig: Partial<Record<EPublicEnvironmentConfigKeys, string>> = {};
  private serviceMetaData: IServiceMetaData;
  private static readonly IS_FEATURE_ENABLED = '1';

  public constructor(private cosCoreClient: CosCoreClient) {}

  public loadAppConfig() {
    return forkJoin([this.getSystemConfigs(), this.cosCoreClient.getServiceMetaInfo()]).pipe(
      tap(([safeSystemConfigs, serviceMetaData]: [Record<EPublicEnvironmentConfigKeys, string>, IServiceMetaData]) => {
        this.appConfig = safeSystemConfigs;
        this.serviceMetaData = serviceMetaData;
      }),
    );
  }

  // TODO: now the BE is sending proper default, this whole thing should be deleted
  private getSystemConfigs(): Observable<Record<EPublicEnvironmentConfigKeys, string>> {
    return this.cosCoreClient.getConfigSystem().pipe(
      map(systemConfigs => (typeof systemConfigs === 'object' ? systemConfigs : {})),
      map(systemConfigs => {
        const safeSystemConfigs: Record<EPublicEnvironmentConfigKeys, string> = {
          AUCTION_SLOTTING_INTERVAL_IN_MIN: systemConfigs.AUCTION_SLOTTING_INTERVAL_IN_MIN || '1',
          DEFAULT_AUCTION_ENDING_HOUR: systemConfigs.DEFAULT_AUCTION_ENDING_HOUR || '9',
          DEFAULT_AUCTION_EVENING_ENDING_HOUR: systemConfigs.DEFAULT_AUCTION_EVENING_ENDING_HOUR || '19',
          DEFAULT_AUCTION_HOT_BID_DURATION_SECONDS: systemConfigs.DEFAULT_AUCTION_HOT_BID_DURATION_SECONDS || '20',
          GUARANTEE_FEATURE_ENABLED: systemConfigs.GUARANTEE_FEATURE_ENABLED || '0',
          USE_CUSTOMIZED_AUCTION_SCHEDULING_STRATEGY: systemConfigs.USE_CUSTOMIZED_AUCTION_SCHEDULING_STRATEGY || '0',
          AUCTION_ROOM_FINALIZATION_HOUR: systemConfigs.AUCTION_ROOM_FINALIZATION_HOUR || '16',
        };

        Object.keys(systemConfigs).forEach(key => {
          if (!systemConfigs[key]) {
            console.warn(`System config ${key} is not defined, using default value: ${safeSystemConfigs[key]}`);
          }
        });

        return safeSystemConfigs;
      }),
    );
  }

  public getConfig(): Partial<Record<EPublicEnvironmentConfigKeys, string>> {
    return this.appConfig;
  }

  public isProductionBackend(): boolean {
    return this.serviceMetaData.environment === 'production' || this.serviceMetaData.environment === '?';
  }

  public isAuctionSlottingActive(): boolean {
    return this.appConfig.USE_CUSTOMIZED_AUCTION_SCHEDULING_STRATEGY === ConfigService.IS_FEATURE_ENABLED;
  }

  public isGuaranteeEnabled(): boolean {
    return this.appConfig.GUARANTEE_FEATURE_ENABLED === ConfigService.IS_FEATURE_ENABLED;
  }
}
