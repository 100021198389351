import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralPipesModule, I18nVehiclePipesModule } from '@caronsale/frontend-pipes';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { GuaranteeBadgeModule } from '@cosCoreComponents/general/guarantee-badge/guarantee-badge.module';

import { TechStateInfoComponent } from './tech-state-info.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ServiceBadgeComponent } from '@cos/partials/service-badge/service-badge.component';
import { VehicleDetailsGridComponent } from '../vehicle-details-grid/vehicle-details-grid.component';
import { CosCheckPlusGuaranteeBadgeComponent } from '@cos/partials/general-info/vehicle-info/tech-state-info/cos-check-plus-guarantee-badge/cos-check-plus-guarantee-badge.component';

@NgModule({
  declarations: [TechStateInfoComponent],
  imports: [
    BaseModule,
    CommonModule,
    CosCheckPlusGuaranteeBadgeComponent,
    EnzoComponentsModule,
    I18nVehiclePipesModule,
    GeneralPipesModule,
    GuaranteeBadgeModule,
    ServiceBadgeComponent,
    VehicleDetailsGridComponent,
  ],
  exports: [TechStateInfoComponent],
})
export class TechStateInfoModule {}
