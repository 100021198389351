import { Injectable, NgZone } from '@angular/core';
import { CosCoreClient } from '@caronsale/frontend-services';
import { environment } from '@cosCoreEnvironments/environment';
import { GrowthBook } from '@growthbook/growthbook';
import { BehaviorSubject, catchError, filter, first, map, Observable, of } from 'rxjs';
import mixpanel from 'mixpanel-browser';

abstract class GrowthbookEvent {
  public readonly name: string;
  public readonly properties: Record<string, unknown>;
  public readonly feature: keyof Features;
}

/**
 * Used internally by growthbook to track experiments
 * It is an exact copy of the datasource query settings in Growthbook
 */
class GrowthbookExperimentStartEvent implements GrowthbookEvent {
  public readonly name = 'GROWTHBOOK_EXPERIMENT_START';

  public readonly feature: keyof Features;

  public readonly properties: {
    $source: 'growthbook';
    experimentId: string;
    variationId: number;
  };

  public constructor(experimentId: string, variationId: number, feature: keyof Features) {
    this.properties = {
      $source: 'growthbook',
      experimentId,
      variationId,
    };
    this.feature = feature;
  }
}

/**
 * Events sent to mixpanel which then are used as metrics for growthbook experiments
 */
type Events = GrowthbookExperimentStartEvent;

/**
 * Values that we can use in growthbook for experiments, targeting features, etc
 * We can have as many as we need
 */
interface Attributes {
  /**
   * Unique session id set by mixpanel
   */
  sessionId: string;
  /**
   * User's email from the last auth result
   */
  userId: string;
}

/**
 * Features defined in growthbook that we can access in the code. Could be boolean, string, JSON or number
 * They are environment based
 */
export interface Features {
  'enable-buyer-self-registration': boolean;
  'show-voucher-selection': boolean;
  'standing-fee-enabled': boolean;
  'pni-bnpl-display-limits-enabled': boolean;
  'crm-buyer-submission-form': boolean;
  'pni-seller-success-report-enabled': boolean;
  'pni-bnpl-backend-order-creation-enabled': boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GrowthbookService {
  /**
   * Growthbook instance
   */
  private growthbook: GrowthBook;

  private featuresLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private featuresLoaded$: Observable<boolean> = this.featuresLoadedSubject.pipe(filter(Boolean));

  public constructor(
    private coreClient: CosCoreClient,
    ngZone: NgZone,
  ) {
    this.growthbook = new GrowthBook<Features>({
      apiHost: environment.growthbookApiUrl,
      clientKey: environment.growthbookClientKey,
      subscribeToChanges: true,
      /**
       * Disable only on prod. When dev mode enabled we can use the growthbook chrome extension
       */
      enableDevMode: !environment.production,
      /**
       * Whenever an A/B feature with a linked experiment this callback is triggered
       * so growthbook can analise the users distribution and defined metrics
       */
      trackingCallback: (experiment, result) => {
        const feature = result.featureId as keyof Features;
        this.track(new GrowthbookExperimentStartEvent(experiment.key, result.variationId, feature));
      },
    });
    this.growthbook.setRenderer(() => {
      if (this.featuresLoadedSubject.value) {
        ngZone.run(() => this.featuresLoadedSubject.next(true));
      }
    });
    this.initMixpanel();
    this.growthbook
      .loadFeatures()
      .catch(() => null)
      .then(() => this.featuresLoadedSubject.next(true));
  }

  /**
   * Check if the given feature is enabled
   */
  public isOn(feature: keyof Features): Observable<boolean> {
    return this.featuresLoaded$.pipe(
      map(() => this.growthbook.isOn(feature)),
      catchError(() => of(false)),
    );
  }

  /**
   * Check if the given feature is disabled
   */
  public isOff(feature: keyof Features): Observable<boolean> {
    return this.featuresLoaded$.pipe(
      map(() => this.growthbook.isOff(feature)),
      catchError(() => of(true)),
    );
  }

  /**
   * Get feature return value defined in growthbook with fallback when is disabled (boolean, number, string or json)
   */
  public getFeatureValue<K extends keyof Features>(feature: K, fallbackValue: Features[K]): Observable<Features[K]> {
    return this.featuresLoaded$.pipe(
      map(() => this.growthbook.getFeatureValue(feature, fallbackValue) as Features[K]),
      catchError(() => of(fallbackValue)),
    );
  }

  /**
   * Set attributes used for experiments and targeting features
   */
  public setUserIdAttribute(userId: string): void {
    try {
      const attributes = this.growthbook.getAttributes() as Attributes;

      this.setAttributes({
        ...attributes,
        userId,
      });
    } catch (error) {
      return;
    }
  }

  /**
   * Track an event used for a given experiment
   * At the moment we use mixpanel but could be replaced by any other storage
   */
  public track(event: Events): void {
    try {
      const feature = event.feature;
      this.getFeatureValue(feature, null)
        .pipe(first())
        .subscribe(featureValue => {
          mixpanel.track(event.name, {
            ...event.properties,
            ...this.getAttributes(),
            ...(feature
              ? {
                  feature,
                  featureValue,
                }
              : {}),
          });
        });
    } catch (error) {
      return;
    }
  }

  /**
   * Initialise mixpanel used to store events used for experiments
   * Could change in the future for another storage
   */
  private initMixpanel(): void {
    mixpanel.init(environment.mixpanelProjectToken, {
      debug: !environment.production,
      loaded: mx => {
        this.setAttributes({
          sessionId: mx.get_distinct_id(),
          userId: this.coreClient.getLastAuthenticationResult().userId,
        });
      },
    });
  }

  /**
   * Allow to set typed attributes only
   */
  private setAttributes(attributes: Attributes): void {
    this.growthbook.setAttributes({
      ...attributes,
    });
  }

  private getAttributes(): Attributes {
    return this.growthbook.getAttributes() as Attributes;
  }

  /**
   * @Internal
   * Get growthbook instance
   * Used only for testing purposes
   */
  public __dangerouslyGetGrowthbookInstance(): GrowthBook {
    return this.growthbook;
  }
}
