/** Used to apply filters to sanitize HTTP request and response data and URL parameters
 * See: Network > https://docs.logrocket.com/reference/network
 *      Browser/URL > https://docs.logrocket.com/reference/browser
 *
 * Note: We can use the LogRocket Fuzzy Search Sanitizer to filter private words easily
 *       https://github.com/LogRocket/logrocket-fuzzy-search-sanitizer
 */

export const LogRocketInitConfig = {
  network: {
    requestSanitizer: request => {
      // Remove Auth Headers from all requests
      request.headers['Authorization'] = null;
      request.headers['Identity'] = null;
      // If the request url contains cognito-idp, remove all headers and body
      if (request.url.toLowerCase().indexOf('cognito-idp') !== -1) {
        request.headers = null;
        request.body = null;
      }
      // Avoiding link user's sensitive info
      if (request.url.toLowerCase().indexOf('authenticated-user') !== -1) {
        // Ignoring the request response pair
        return null;
      }
      return request;
    },
    responseSanitizer: response => {
      // If the response is from aws/cognito-idp, remove all response data
      if (response.headers['X-Amzn-Requestid']) {
        return null;
      }
      if (response.url && response.url.toLowerCase().indexOf('cognito-idp') !== -1) {
        delete response.body;
      }
      if (response.body && response.body.AuthenticationResult) {
        delete response.body;
      }
      return response;
    },
  },
};
