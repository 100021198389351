import { Component, ElementRef, HostListener, Inject, InjectionToken, NgZone, OnInit, Optional } from '@angular/core';
import { CdkDialogContainer, DialogConfig, DialogModule, DialogRef } from '@angular/cdk/dialog';
import { CommonModule, DOCUMENT } from '@angular/common';
import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from '@angular/cdk/a11y';
import { OverlayRef } from '@angular/cdk/overlay';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { DEFAULT_DIALOG_TYPE, DEFAULT_DIALOG_VARIANT, EnzoDialogBase, EnzoDialogType, EnzoVariant } from '@cosCoreComponents/modal-dialogs/enzo-dialog-base';
import { EEnzoDialogResult } from '@cosCoreComponents/modal-dialogs/enzo-dialog.service';

export interface IEnzoDialogContainerParams {
  footerButtonClickSubject: Subject<EEnzoDialogResult>;
  dataCyBase?: string;
  showLoadingIndicator?: boolean;
}

export const NG_ENZO_DIALOG_CONTAINER_PARAMS: InjectionToken<IEnzoDialogContainerParams> = new InjectionToken<IEnzoDialogContainerParams>(
  'IEnzoDialogContainerParams',
);

@Component({
  templateUrl: './enzo-dialog-container.component.html',
  styleUrls: ['./enzo-dialog-container.component.scss'],
  standalone: true,
  imports: [CommonModule, DialogModule, EnzoComponentsModule, TranslateModule],
})
export class EnzoDialogContainerComponent<R, D extends DialogConfig, C extends EnzoDialogBase> extends CdkDialogContainer<D> implements OnInit {
  public variant: EnzoVariant;
  public hasCancelButton: boolean = false;
  public cancelButtonKey: string;
  public cancelButtonDataCy: string;
  public leftButtonKey: string;
  public leftButtonDataCy: string;
  public rightButtonKey: string;
  public rightButtonDataCy: string;
  public panelClass: string | string[] = [];
  public isLoading: boolean = false;

  private dialogType: EnzoDialogType;

  public constructor(
    private translateService: TranslateService,
    private dialogRef: DialogRef<R, C>,
    @Inject(NG_ENZO_DIALOG_CONTAINER_PARAMS) private dialogContainerParams: IEnzoDialogContainerParams,
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    @Optional() @Inject(DOCUMENT) document: any,
    @Inject(DialogConfig) dialogConfig: D,
    interactivityChecker: InteractivityChecker,
    ngZone: NgZone,
    overlayRef: OverlayRef,
    focusMonitor?: FocusMonitor,
  ) {
    super(elementRef, focusTrapFactory, document, dialogConfig, interactivityChecker, ngZone, overlayRef, focusMonitor);
    this.panelClass = dialogConfig.panelClass;
  }

  public ngOnInit() {
    this.variant = this.dialogRef.componentInstance.enzoDialogVariant ?? DEFAULT_DIALOG_VARIANT;
    this.dialogType = this.dialogRef.componentInstance.enzoDialogType ?? DEFAULT_DIALOG_TYPE;

    switch (this.dialogType) {
      case 'leftRightCancel':
        this.hasCancelButton = true;
      // fall through
      case 'leftRight':
        this.leftButtonKey = this.dialogRef.componentInstance.footerButtonBaseKey + '.left';
        this.rightButtonKey = this.dialogRef.componentInstance.footerButtonBaseKey + '.right';
        break;

      case 'yesNoCancel':
        this.hasCancelButton = true;
      // fall through
      case 'yesNo':
        this.leftButtonKey = 'general.no';
        this.rightButtonKey = 'general.yes';
        break;

      case 'rightCancel':
        this.hasCancelButton = true;
        this.rightButtonKey = this.dialogRef.componentInstance.footerButtonBaseKey + '.right';
        break;

      case 'confirmCancel':
        this.hasCancelButton = true;
        this.rightButtonKey = this.dialogRef.componentInstance.footerButtonBaseKey + '.confirm';
        break;

      // visually identical to confirmCancel but uses ".reject" key from the translations
      case 'confirmReject':
        this.hasCancelButton = true;
        this.cancelButtonKey = this.dialogRef.componentInstance.footerButtonBaseKey + '.reject';
        this.cancelButtonKey = this.isTranslationKeyDefined(this.cancelButtonKey) ? this.cancelButtonKey : 'general.cancel';
        // to be compatible with the legacy I18nConfirmationDialog this must be called "decline"
        this.cancelButtonDataCy = this.dialogContainerParams.dataCyBase ? this.dialogContainerParams.dataCyBase + 'decline' : null;
        this.rightButtonKey = this.dialogRef.componentInstance.footerButtonBaseKey + '.confirm';
        this.rightButtonKey = this.isTranslationKeyDefined(this.rightButtonKey) ? this.rightButtonKey : 'general.confirm';
        this.rightButtonDataCy = this.dialogContainerParams.dataCyBase ? this.dialogContainerParams.dataCyBase + 'confirm' : null;
        break;

      case 'okCancel':
        this.hasCancelButton = true;
      // fall through
      case 'ok':
        this.rightButtonKey = 'general.ok';
        break;
    }

    if (this.hasCancelButton && !this.cancelButtonKey) {
      const customCancelKey = this.dialogRef.componentInstance.footerButtonBaseKey + '.cancel';
      this.cancelButtonKey = this.dialogRef.componentInstance.isTranslationKeyDefined(this.translateService, customCancelKey)
        ? customCancelKey
        : 'general.cancel';
    }
  }

  public onCancel() {
    this.dialogContainerParams.footerButtonClickSubject.next(this.dialogType === 'confirmReject' ? EEnzoDialogResult.REJECT : EEnzoDialogResult.CANCEL);
  }

  public onLeft(): void {
    const value =
      this.dialogType === 'yesNo' || this.dialogType === 'yesNoCancel'
        ? EEnzoDialogResult.NO
        : this.dialogType === 'confirmReject'
          ? EEnzoDialogResult.REJECT
          : EEnzoDialogResult.LEFT;
    this.dialogContainerParams.footerButtonClickSubject.next(value);
  }

  @HostListener('window:keydown.Enter', ['$event'])
  public onRight($event: KeyboardEvent): void {
    const value =
      this.dialogType === 'yesNo' || this.dialogType === 'yesNoCancel'
        ? EEnzoDialogResult.YES
        : this.dialogType === 'ok' || this.dialogType === 'okCancel'
          ? EEnzoDialogResult.OK
          : this.dialogType === 'confirmReject' || this.dialogType === 'confirmCancel'
            ? EEnzoDialogResult.CONFIRM
            : EEnzoDialogResult.RIGHT;
    $event.preventDefault(); // do not press the button, where the mouse is, again (it probably opened this dialog)
    if (this.dialogContainerParams.showLoadingIndicator) {
      this.isLoading = true;
      this.hasCancelButton = false;
    }
    this.dialogContainerParams.footerButtonClickSubject.next(value);
  }

  private isTranslationKeyDefined(key: string): boolean {
    return this.dialogRef.componentInstance.isTranslationKeyDefined(this.translateService, key);
  }
}
