<ng-container *ngIf="canDownload || externalReportType === EReportType.PRIVATE">
  <button
    *ngIf="theme === 'expose' && canDownload"
    (click)="openReport()"
    [disabled]="isLoading"
    mat-flat-button
    color="primary"
    data-pf="vehicle-report-button">
    <fa-icon class="vehicle-expose-icon" size="lg" icon="file-alt"></fa-icon>
    {{ 'vehicle.to-vehicle-expose' | translate }}
  </button>

  <button *ngIf="theme === 'expose-small' && canDownload" class="expose-small" (click)="openReport()" [disabled]="isLoading">
    <fa-icon icon="file-pdf"></fa-icon>
    {{ 'vehicle.to-vehicle-expose-small' | translate }}
  </button>

  <ng-container *ngIf="theme === 'default'">
    <app-service-badge *ngIf="isCosReview" service="cos-check" (click)="!isLoading && openReport()" [matTooltip]="'vehicle.checked-by-cos' | translate">
      <enzo-icon service-badge-icon icon="cos-check"></enzo-icon>
      <div service-badge-label class="cos-check">
        <span>COS</span>
        Check
      </div>
    </app-service-badge>

    <app-service-badge
      *ngIf="!isCosReview"
      [service]="externalReportType"
      (click)="!isLoading && openReport()"
      [clickable]="canDownload"
      [matTooltip]="
        externalReportType === EReportType.PRIVATE
          ? ('vehicle.checked-privately' | translate)
          : ('vehicle.checked-by' | translate: { checkedBy: auction.associatedVehicle.origin | i18nVehicleCreationOrigin | translate })
      ">
      <enzo-icon service-badge-icon icon="cos-check"></enzo-icon>
      <div service-badge-label class="third-party">
        {{
          (auction.associatedVehicle.origin === EVehicleCreationOrigin.MERCEDES_AUSTRIA
            ? 'vehicle.origin.report-tuv'
            : (auction.associatedVehicle.origin | i18nVehicleCreationOrigin)
          ) | translate
        }}
      </div>
    </app-service-badge>
  </ng-container>

  <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
</ng-container>

<app-service-badge *ngIf="theme === 'battery'" service="battery" (click)="openBatteryCertificate()">
  <enzo-icon service-badge-icon icon="electric"></enzo-icon>
  <div service-badge-label>{{ 'vehicle.battery.certificate' | translate }}</div>
</app-service-badge>
