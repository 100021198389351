import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { COS_CONSTANTS } from '@caronsale/frontend-services';
import { NotificationCenterService } from '@cosCoreFeatures/common/notification-center/services/notification.service';

export interface IBuyerGuaranteeAdvertiseDialogParams {
  auctionUuid: string;
  auctionLabel: string;
  vehicleImage: string;
  displayButtons: boolean;
}

export interface IBuyerGuaranteeAdvertiseDialogResult {
  confirmed: boolean;
}

@Component({
  selector: 'app-buyer-guarantee-advertise-dialog',
  templateUrl: './buyer-guarantee-advertise-dialog.component.html',
  styleUrls: ['./buyer-guarantee-advertise-dialog.component.scss'],
})
export class BuyerGuaranteeAdvertiseDialogComponent implements OnInit {
  public readonly CONDITIONS_LINK: string;
  public translateTemplates: Record<string, string>;

  // TODO: Sync these values with the BE.
  private guaranteePrices: Record<string, number> = {
    discount: 99,
    original: 150,
  };

  public constructor(
    @Inject(MAT_DIALOG_DATA) public data: IBuyerGuaranteeAdvertiseDialogParams,
    private dialogRef: MatDialogRef<BuyerGuaranteeAdvertiseDialogComponent>,
    private constants: COS_CONSTANTS,
    private notificationCenterService: NotificationCenterService,
  ) {
    this.CONDITIONS_LINK = this.constants.GUARANTEE_CONDITIONS_URL;
  }

  public ngOnInit(): void {
    this.notificationCenterService.trackGuaranteeNotificationTrigger('guarantee_popup_opened', this.data.auctionUuid);

    this.translateTemplates = {
      auctionLabel: `{prefix} <span>${this.data.auctionLabel}</span>`,
      disclaimer: `{text} <a target="_blank" href="${this.CONDITIONS_LINK}"><u>{label}</u></a>.`,
      discount: `{text} <span>${this.guaranteePrices.discount}€</span> {comparator} ${this.guaranteePrices.original}€`,
    };
  }

  public onConfirm(): void {
    this.notificationCenterService.trackGuaranteeNotificationTrigger('guarantee_popup_converted', this.data.auctionUuid);
    const result: IBuyerGuaranteeAdvertiseDialogResult = { confirmed: true };
    this.dialogRef.close(result);
  }

  public onCancel(): void {
    const result: IBuyerGuaranteeAdvertiseDialogResult = { confirmed: false };
    this.dialogRef.close(result);
  }
}
