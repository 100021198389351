import { Component, Input, OnChanges } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Observable, finalize, map } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { IAuction } from '@caronsale/cos-models';
import { EVehicleCreationOrigin } from '@caronsale/cos-vehicle-models';
import { CosCoreClient } from '@caronsale/frontend-services';
import { VehicleReportTranslatedModalComponent } from './vehicle-report-translated-modal/vehicle-report-translated-modal.component';

export enum EReportType {
  DEKRA = 'dekra',
  TUV = 'tuv',
  PRIVATE = 'private',
  THIRD_PARTY = 'third-party',
}

@Component({
  selector: 'app-vehicle-origin-report-button',
  templateUrl: './vehicle-origin-report-button.component.html',
  styleUrls: ['./vehicle-origin-report-button.component.scss'],
})
export class VehicleOriginReportButtonComponent implements OnChanges {
  @Input() public auction: IAuction;
  @Input() public theme: 'battery' | 'default' | 'expose' | 'expose-small' = 'default';
  @Input() public disableOpenReport = false;

  public isLoading = false;
  public isCosReview = false;
  public canDownload = false;
  public externalReportType = '';
  public EReportType = EReportType;
  public EVehicleCreationOrigin = EVehicleCreationOrigin;

  public constructor(
    private readonly translationService: TranslateService,
    private readonly cosClient: CosCoreClient,
    private readonly dialog: MatDialog,
    private readonly snackBar: MatSnackBar,
  ) {}

  public ngOnChanges(): void {
    this.isCosReview = this.auction.associatedVehicle.origin === EVehicleCreationOrigin.REVIEW_COS;
    this.externalReportType = this.getReportType(this.auction.associatedVehicle.origin);
    this.canDownload = this.isCosReview || this.theme === 'expose-small' || Boolean(this.getReportUrl());
  }

  public openReport(): void {
    if (this.disableOpenReport) {
      return;
    }

    const reportUrl = this.getReportUrl();

    if (reportUrl) {
      // Prevent browser from using locally-cached file
      window.open(reportUrl + `?t=${new Date().getTime()}`, '_blank');
      return;
    }

    if (this.externalReportType === EReportType.PRIVATE && !reportUrl) {
      return;
    }

    const currentLang = this.translationService.currentLang;
    this.isLoading = true;

    this.translateReport(currentLang)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: url => {
          this.auction.associatedVehicle.urlsByLanguageToExpose[currentLang] = url;
          this.dialog.open(VehicleReportTranslatedModalComponent, {
            width: '600px',
            data: { auction: this.auction, reportUrl: url },
          });
        },
        error: errorResponse => {
          const errorMessage: string = this.translationService.instant('vehicle.expose-translation.error', { error: errorResponse.error.message });
          this.snackBar.open(errorMessage, null, { duration: 5000 });
        },
      });
  }

  public openBatteryCertificate() {
    const certificateUrl = this.auction.associatedVehicle.urlToBatteryConditionCertificate;
    if (certificateUrl) {
      window.open(certificateUrl, '_blank');
      return;
    }
  }

  private translateReport(language: string): Observable<string> {
    return this.cosClient.requestWithPrivileges('post', `/media/auction/${this.auction.uuid}/summary/${language}`).pipe(map(res => res.url));
  }

  private getReportUrl(): string {
    if (this.isCosReview || this.theme === 'expose-small') {
      return this.auction.associatedVehicle.urlsByLanguageToExpose?.[this.translationService.currentLang];
    }
    return this.auction.associatedVehicle.urlToAttachment1;
  }

  private getReportType(vehicleReport: EVehicleCreationOrigin): EReportType {
    switch (vehicleReport) {
      case EVehicleCreationOrigin.REVIEW_DEKRA:
        return EReportType.DEKRA;
      case EVehicleCreationOrigin.REVIEW_TUV_NORD:
      case EVehicleCreationOrigin.REVIEW_TUV_RHEINLAND:
      case EVehicleCreationOrigin.REVIEW_TUV_SUD:
      case EVehicleCreationOrigin.MERCEDES_AUSTRIA:
        return EReportType.TUV;
      case EVehicleCreationOrigin.SELF_REVIEWED:
        return EReportType.PRIVATE;
      default:
        return EReportType.THIRD_PARTY;
    }
  }
}
