import { Component, Input } from '@angular/core';

import { AdditionalTax, IAuctionBiddingData } from '@caronsale/cos-models';

@Component({
  selector: 'app-additional-tax-info',
  templateUrl: './additional-tax-info.component.html',
  styleUrls: ['./additional-tax-info.component.scss'],
})
export class AdditionalTaxInfoComponent {
  @Input()
  public showTaxType: boolean = true;

  @Input()
  public auctionBiddingData: IAuctionBiddingData;

  public hasAdditionalTax(): boolean {
    return AdditionalTax.isAdditionalTax(this.auctionBiddingData.additionalTaxType);
  }

  public isAdditionalTaxRelative(): boolean {
    return AdditionalTax.isRelativeTax(this.auctionBiddingData.additionalTaxType);
  }

  public isAdditionalTaxAbsolute(): boolean {
    return AdditionalTax.isAbsoluteTax(this.auctionBiddingData.additionalTaxType);
  }
}
