import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { CosAuthService } from '@cosCoreServices/cos-auth/cos-auth.service';
import { IAuthenticationResult } from '@caronsale/cos-models';
import { CosCoreClient } from '@caronsale/frontend-services';

// includes authGuard() to be able to store the requested privileges when the user is not authenticated
export function privilegesGuard(expectedPrivilegesSpec: string[] | string): CanActivateFn {
  return (_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const cosAuthService = inject(CosAuthService);
    const expectedPrivileges = Array.isArray(expectedPrivilegesSpec) ? expectedPrivilegesSpec : [expectedPrivilegesSpec];
    const currentAuthentication: IAuthenticationResult = inject(CosCoreClient).getLastAuthenticationResult();

    cosAuthService.setCurrentAuthentication(currentAuthentication);

    if (!currentAuthentication.authenticated) {
      cosAuthService.saveUrlAndGoToLogin(state.url, expectedPrivileges);
      return false;
    }

    const usersPrivileges = currentAuthentication.privileges.split('~');

    if (!expectedPrivileges.some(expected => usersPrivileges.includes(expected))) {
      cosAuthService.goToHomePath(currentAuthentication);
      return false;
    }
    return true;
  };
}
