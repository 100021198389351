import { Injectable, NgZone, inject } from '@angular/core';
import PhotoSwipe, { SlideData } from 'photoswipe';

@Injectable({ providedIn: 'root' })
export class FullscreenGalleryService {
  private ngZone = inject(NgZone);

  public open(index: number, dataSource: SlideData[], imageTrigger?: HTMLImageElement): PhotoSwipe {
    dataSource = dataSource.map(data => ({
      ...data,
      // this initial setting avoids the image flickering while loading.
      width: 1,
      height: 1,
    }));

    const photoSwipe = new PhotoSwipe({ index, dataSource });

    this.ngZone.runOutsideAngular(() => {
      // when provided this sets the needed properties for the opening and closing animations.
      if (imageTrigger) {
        photoSwipe.addFilter('thumbEl', () => imageTrigger);
        photoSwipe.addFilter('placeholderSrc', () => imageTrigger.src);
      }

      // to support different aspect ratios this loads images in their original sizes.
      const images = dataSource.map(() => new Image());
      const setDataSource = photoSwipe.options.dataSource;

      photoSwipe.on('change', () => {
        const currentIndex = photoSwipe.currIndex;
        const indexesToLoad = [(currentIndex - 1 + dataSource.length) % dataSource.length, currentIndex, (currentIndex + 1) % dataSource.length];

        for (const idx of indexesToLoad) {
          const item = setDataSource[idx];
          const img = images[idx];
          if (!img.src) {
            img.onload = () => {
              item.width = img.naturalWidth;
              item.height = img.naturalHeight;
              photoSwipe.refreshSlideContent(idx);
            };
            img.src = item.src;
          }
        }
      });

      photoSwipe.init();

      // whenever overlayText is provided this creates the elements that go on top of the image containing said text.
      if (dataSource.some(data => data.overlayText)) {
        photoSwipe.ui.registerElement({
          name: 'custom-caption',
          appendTo: 'wrapper',
          html: dataSource[index].overlayText ? `<enzo-text>${dataSource[index].overlayText.join(', ')}</enzo-text>` : '',
          onInit: (el, pswp) =>
            pswp.on('change', () => {
              if (pswp.currSlide.data.overlayText) {
                el.innerHTML = `<enzo-text>${pswp.currSlide.data.overlayText.join(', ')}</enzo-text>`;
              } else {
                el.innerHTML = '';
              }
            }),
        });
      }
    });

    return photoSwipe;
  }
}
