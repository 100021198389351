import { Pipe, PipeTransform } from '@angular/core';
import { EAdditionalTaxType, I18nFormatUtils } from '@caronsale/cos-models';

@Pipe({
  name: 'i18nAdditionalTaxType',
})
export class I18nAdditionalTaxTypePipe implements PipeTransform {
  public transform(value: EAdditionalTaxType): string {
    return I18nFormatUtils.formatAuctionAdditionalTaxType(value);
  }
}
