import { ChangeDetectionStrategy, Component, ElementRef, OnInit, booleanAttribute, computed, inject, input, viewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { debounceTime, switchMap } from 'rxjs';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatTooltipModule } from '@angular/material/tooltip';

import LogRocket from 'logrocket';

import { TranslateModule } from '@ngx-translate/core';
import { EnzoComponentsModule, EnzoTextfield } from '@caronsale/enzo-angular';

import { NotesRepository } from '../services/notes/notes.repository';
import { NotesService } from '../services/notes/notes.service';

@Component({
  standalone: true,
  selector: 'app-notes-button',
  templateUrl: './notes-button.component.html',
  styleUrls: ['./notes-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, ReactiveFormsModule, EnzoComponentsModule, TranslateModule, MatTooltipModule],
})
export class NotesButtonComponent implements OnInit {
  private notesService = inject(NotesService);
  private notesRepository = inject(NotesRepository);
  private elementRef = inject(ElementRef); // TODO remove when firefox and safari support position anchor

  public auctionUuid = input.required<string>();
  public bright = input<boolean, boolean>(false, { transform: booleanAttribute });
  public popoverOnTop = input<boolean, boolean>(false, { transform: booleanAttribute });
  public iconSize = input<'small' | 'medium'>('medium');

  public notesControl = new FormControl<string>('');
  public popoverState: 'open' | 'closed' = 'closed';
  public notesPopover = viewChild<ElementRef<HTMLDivElement>>('notesPopover');
  public enzoInput = viewChild<{ el: EnzoTextfield }>('enzoInput');

  public anchorName = computed<string>(() => `--${this.auctionUuid()}${this.bright()}`);
  public auctionNote$ = toObservable(this.auctionUuid).pipe(switchMap(auctionUuid => this.notesRepository.selectNote(auctionUuid)));
  public auctionNote = toSignal(this.auctionNote$);
  public highlight = computed<boolean>(() => Boolean(this.auctionNote()?.content));

  public constructor() {
    this.notesControl.valueChanges
      .pipe(
        debounceTime(250),
        switchMap(note => this.notesService.setNote(note, this.auctionUuid())),
        takeUntilDestroyed(),
      )
      .subscribe();

    this.auctionNote$.pipe(takeUntilDestroyed()).subscribe(note => {
      if (this.popoverState === 'open') {
        return;
      }
      this.notesControl.setValue(note?.content, { emitEvent: false });
    });
  }

  public ngOnInit(): void {
    if (this.notesRepository.hasNote(this.auctionUuid())) {
      this.notesControl.setValue(this.notesRepository.getNote(this.auctionUuid()).content, { emitEvent: false });
    } else {
      this.notesService.requestNote(this.auctionUuid());
    }
  }

  public toggleNotesPopover(): void {
    if (this.popoverState === 'closed') {
      this.notesPopover().nativeElement.showPopover();
      LogRocket.track('note_button_click', { auctionUuid: this.auctionUuid(), isBidFooter: this.popoverOnTop() });
      if (!this.notesControl.value) {
        setTimeout(() => {
          this.enzoInput().el.forceFocus();
        }, 250);
      }
    } else {
      this.notesPopover().nativeElement.hidePopover();
    }
  }

  // TODO remove when firefox and safari support position anchor
  public getPopoverTopPosition(): number {
    if (this.popoverOnTop()) {
      return this.elementRef.nativeElement.getBoundingClientRect().top - 200;
    }
    return this.elementRef.nativeElement.getBoundingClientRect().bottom + window.scrollY;
  }

  // TODO remove when firefox and safari support position anchor
  public getPopoverLeftPosition(): number {
    if (this.popoverOnTop()) {
      return this.elementRef.nativeElement.getBoundingClientRect().right - 282;
    }
    return this.elementRef.nativeElement.getBoundingClientRect().left;
  }
}
