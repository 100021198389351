import { Injectable } from '@angular/core';
import { CosCoreClient } from '@caronsale/frontend-services';
import { IVehicle } from '@caronsale/cos-vehicle-models';

interface IConfirmResponse {
  associatedVehicle: IVehicle;
}

@Injectable()
export class CosPickupService {
  public constructor(private cosClient: CosCoreClient) {}

  private disableCosCoreErrorHandling() {
    this.cosClient.setNotFoundHandler(() => {
      /**/
    });
  }

  public confirmByUUID(uuid: string): Promise<IConfirmResponse> {
    this.disableCosCoreErrorHandling();
    return this.cosClient.request('put', '/public/auction/pickup/qr/' + uuid).toPromise();
  }

  public confirmByPin(pin: string, captcha: string): Promise<IConfirmResponse> {
    this.disableCosCoreErrorHandling();
    return this.cosClient.request('put', '/public/auction/pickup/pin/' + pin + '?g-recaptcha-response=' + captcha).toPromise();
  }
}
