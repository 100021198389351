import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { IAuction } from '@caronsale/cos-models';
import { EVehiclePart, EVehicleTechState, IVehicleTechState } from '@caronsale/cos-vehicle-models';
import { COS_CONSTANTS } from '@caronsale/frontend-services';

import { Popover } from '@cosCoreFeatures/@cos/cos-popover/services/popover.service';
import { ConfigService } from '@cosCoreServices/config/config.service';
import { GoogleAnalyticsService } from '@cosCoreServices/google-analytics/google-analytics.service';

@Component({
  selector: 'app-tech-state-info',
  templateUrl: './tech-state-info.component.html',
  styleUrls: ['./tech-state-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TechStateInfoComponent implements OnChanges {
  @Input() public auction: IAuction;
  @Input() public displayTitle = true;

  public normalParts: IVehicleTechState[] = [];
  public abnormalParts: IVehicleTechState[] = [];
  public wearParts: IVehicleTechState[] = [];
  public otherTechState: IVehicleTechState;
  public vehiclePartEngine = EVehiclePart.ENGINE;
  public EVehicleTechState = EVehicleTechState;

  public shouldBeDisplayed: boolean;
  public guaranteeFeatureEnabled: boolean;

  public readonly guaranteeConditionsUrl: string = this.constants.GUARANTEE_CONDITIONS_URL;
  public readonly partInfoListTemplates = {
    [EVehiclePart.ENGINE]:
      '<ul> <li>{crankcase}</li> <li>{crankshaft}</li> <li>{pistons}</li> <li>{connecting-rod}</li> <li>{rod-bearings}</li> <li>{main-bearing}</li> <li>{oil-pump}</li> <li>{timing-chain}</li> <li>{turbocharger}</li> <li>{cylinder-head}</li> </ul>',
    [EVehiclePart.TRANSMISSION]:
      '<ul> <li>{gear-case}</li> <li>{shafts}</li> <li>{gear-pairs}</li> <li>{roll-Lager}</li> <li>{synchronizer-body}</li> <li>{synchronizer-rings}</li> <li>{sliding-pieces}</li> <li>{shift-sleeves}</li> <li>{shift-claws}</li> </ul>',
    [EVehiclePart.TRANSFER_CASE]: '<ul> <li>{all-components}</li> </ul>',
    [EVehiclePart.STEERING]: '<ul> <li>{steering-gear}</li> <li>{hydraulic-pump}</li> <li>{steering-motor}</li> </ul>',
    [EVehiclePart.DIFFERENTIAL]: '<ul> <li>{gears}</li> </ul>',
    [EVehiclePart.BATTERY]: '<ul> <li>{start-battery}</li> </ul>',
    [EVehiclePart.AIR_CONDITIONER]: '<ul> <li>{exceptions}</li> </ul>',
  };

  public constructor(
    private configService: ConfigService,
    private popover: Popover,
    private translateService: TranslateService,
    private constants: COS_CONSTANTS,
    private googleAnalyticsService: GoogleAnalyticsService,
  ) {}

  public ngOnChanges(): void {
    if (this.auction) {
      this.guaranteeFeatureEnabled = this.configService.isGuaranteeEnabled() && this.auction.isCosCheckPlusGuaranteeEnabled;

      this.setTechStates();

      this.shouldBeDisplayed =
        this.normalParts.length > 0 ||
        this.abnormalParts.length > 0 ||
        this.wearParts.length > 0 ||
        !!this.auction.associatedVehicle.urlToMotorSound ||
        !!this.otherTechState;
    }
  }

  private setTechStates(): void {
    const techStateOrder = [
      EVehiclePart.ENGINE,
      EVehiclePart.TRANSMISSION,
      EVehiclePart.TRANSFER_CASE,
      EVehiclePart.STEERING,
      EVehiclePart.DIFFERENTIAL,
      EVehiclePart.BATTERY,
      EVehiclePart.AIR_CONDITIONER,
      EVehiclePart.BRAKING_SYSTEM,
      EVehiclePart.CHASSIS,
      EVehiclePart.CLUTCH,
    ];

    const wearParts = [EVehiclePart.BRAKING_SYSTEM, EVehiclePart.CHASSIS, EVehiclePart.CLUTCH];

    const vehicleTechState = this.auction.associatedVehicle.technicalState;

    const techStates = vehicleTechState
      .sort((st1, st2) => techStateOrder.indexOf(st1.part) - techStateOrder.indexOf(st2.part))
      .filter(
        st =>
          st.part !== EVehiclePart.OTHER &&
          // removes transfer case or ac from the techState if they are NOT_AVAILABLE
          (![EVehiclePart.TRANSFER_CASE, EVehiclePart.AIR_CONDITIONER].includes(st.part) || st.state !== EVehicleTechState.NOT_AVAILABLE),
      );

    this.otherTechState = vehicleTechState.find(st => st.part === EVehiclePart.OTHER);

    this.normalParts = [];
    this.abnormalParts = [];
    this.wearParts = [];

    techStates.forEach(state => {
      if (wearParts.includes(state.part)) {
        this.wearParts.push(state);
      } else if (state.state === EVehicleTechState.OK) {
        this.normalParts.push(state);
      } else {
        this.abnormalParts.push(state);
      }
    });
  }

  public showGuaranteePartDetailsPopover(event: Event, vehiclePart: IVehicleTechState): void {
    const listInfoKey: string = EVehiclePart[vehiclePart.part].toLowerCase().replace('_', '-');
    const urlLabelLink = this.translateService.instant('auction.guarantee.conditions.message', {
      messageTemplate: `<a target='_blank' href='${this.guaranteeConditionsUrl}'><u>{label}</u></a>`,
    });
    const listContent = this.translateService.instant(`auction.guarantee.part-list.${listInfoKey}.message`, {
      messageTemplate: this.partInfoListTemplates[vehiclePart.part],
    });

    this.popover.open({
      width: 300,
      content: this.translateService.instant('auction.guarantee.part-list.header', {
        urlLabelLink,
        listContent,
      }),
      origin: event.currentTarget as HTMLElement,
      customClasses: ['check-bullets'],
    });
  }

  public showLearnMorePopover(event: Event): void {
    const urlLabelLink = this.translateService.instant('auction.guarantee.conditions.message', {
      messageTemplate: `<a target='_blank' href='${this.guaranteeConditionsUrl}'><u>{label}</u></a>`,
    });

    this.popover.open({
      width: 300,
      content: this.translateService.instant('auction.guarantee.coverage-info', {
        urlLabelLink,
      }),
      origin: event.currentTarget as HTMLElement,
    });
  }

  public trackAudioPlay() {
    this.googleAnalyticsService.trackAudioPlay();
  }
}
