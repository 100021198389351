import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehiclePipesModule } from '@caronsale/frontend-pipes';

import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { ExpandablePanelModule } from '@cos/partials/expandable-panel/expandable-panel.module';

import { PaintStatusInfoModule } from '../paint-status-info/paint-status-info.module';
import { TiresInfoComponent } from '../tires-info/tires-info.component';
import { TechStateInfoModule } from '../tech-state-info/tech-state-info.module';

import { VehicleConditionDataComponent } from './vehicle-condition-data.component';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { VehicleDamagesComponent } from '../vehicle-damages/vehicle-damages.component';

@NgModule({
  declarations: [VehicleConditionDataComponent],
  imports: [
    CommonModule,
    ExpandablePanelModule,
    BaseModule,
    EnzoComponentsModule,
    PaintStatusInfoModule,
    TechStateInfoModule,
    TiresInfoComponent,
    VehicleDamagesComponent,
    VehiclePipesModule,
  ],
  exports: [VehicleConditionDataComponent],
})
export class VehicleConditionDataModule {}
