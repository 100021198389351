import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

import { EPrebookedServiceGuaranteeOptionType, EPrebookedServiceType, IBuyerAuctionView, PrebookedServicesUtils } from '@caronsale/cos-models';
import {
  BuyerVehiclePaymentDialogComponent,
  IBuyerVehiclePaymentDialogData,
} from '@cosCoreComponentsBuyer/buyer-vehicle-payment-dialog/buyer-vehicle-payment-dialog.component';
import { CosBuyerClientService } from '@cosCoreServices/cos-salesman-client/cos-buyer-client.service';
import { I18nConfirmationDialogComponent } from '@cosCoreComponentsGeneral/i18n/confirmation-dialog/i18n-confirmation-dialog.component';
import { PrebookedServicesService } from '@cosCoreServices/prebooked-services/prebooked-services.service';
import { Observable, of, finalize, switchMap, tap } from 'rxjs';
import { ConfigService } from '@cosCoreServices/config/config.service';

@Component({
  selector: 'app-payment-control',
  templateUrl: './payment-control.component.html',
  styleUrls: ['./payment-control.component.scss'],
})
export class PaymentControlComponent {
  @Input()
  public auction: IBuyerAuctionView;

  @Input()
  public isPrimary: boolean = true;

  @Output()
  public refresh = new EventEmitter();

  public isMarkingAsPaid: boolean;

  public constructor(
    private dialog: MatDialog,
    private cosBuyerClientService: CosBuyerClientService,
    private prebookedServicesService: PrebookedServicesService,
    private configService: ConfigService,
  ) {
    this.isMarkingAsPaid = false;
  }

  public handleShowPaymentInfo($event: Event) {
    $event.stopPropagation();

    this.openGuaranteeAdvertiseDialog()
      .pipe(
        switchMap(advertiseSkipped => {
          if (!advertiseSkipped) {
            this.refresh.emit();
          }

          return this.dialog
            .open<BuyerVehiclePaymentDialogComponent, IBuyerVehiclePaymentDialogData>(BuyerVehiclePaymentDialogComponent, {
              maxWidth: 'none',
              width: '450px',
              data: {
                purchase: this.auction,
              },
            })
            .afterClosed();
        }),
      )
      .subscribe({
        next: (isConfirmed: boolean) => {
          if (isConfirmed) {
            // TODO: REFACTOR NESTED SUBSCRIBE
            this.markAsPaid(this.auction);
          }
        },
        // TODO: implement some error msg
        error: error => console.log(error),
      });
  }

  public markAsPaid(purchase: IBuyerAuctionView) {
    I18nConfirmationDialogComponent.showConfirmDialog(this.dialog, 'dialog.buyer.mark-as-paid', {}, '400px')
      .pipe(
        tap(() => (this.isMarkingAsPaid = true)),
        switchMap(() => this.cosBuyerClientService.confirmOutgoingPayment({ ...purchase, isPaidByBuyer: true })),
        finalize(() => (this.isMarkingAsPaid = false)),
      )
      .subscribe({
        next: () => this.refresh.emit(),
        error: error => console.log(error),
      });
  }

  public isVehicleReauctioned(): boolean {
    return this.auction.remainingDaysUntilReauctioning === 0;
  }

  public handleCosPayClick($event: Event) {
    $event.stopPropagation();

    this.openGuaranteeAdvertiseDialog().subscribe({
      next: advertiseSkipped => {
        if (!advertiseSkipped) {
          this.refresh.emit();
        }

        window.open(this.auction.urlToPaymentSite, '_blank');
      },
    });
  }

  public openGuaranteeAdvertiseDialog(): Observable<boolean | void> {
    const isCosCheckPlusAlreadyBooked = PrebookedServicesUtils.isOptionSelectedForType(
      this.auction.prebookedServices,
      EPrebookedServiceType.GUARANTEE,
      EPrebookedServiceGuaranteeOptionType.COS_CHECK_PLUS,
    );

    if (this.auction.isCosCheckPlusGuaranteeEnabled && this.configService.isGuaranteeEnabled() && !isCosCheckPlusAlreadyBooked) {
      return this.prebookedServicesService.openGuaranteeAdvertiseDialog(
        this.auction.uuid,
        this.auction.label,
        this.auction.associatedVehicle.vehicleImages[0].url,
      );
    }

    return of(true);
  }
}
