import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';
import { IAuction } from '@caronsale/cos-models';
import { EVehicleCreationOrigin } from '@caronsale/cos-vehicle-models';
import { Icon } from '@caronsale/enzo';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';

import { VehicleOriginReportButtonModule } from '../../vehicle-origin-report-button/vehicle-origin-report-button.module';

const safeOrigins = [EVehicleCreationOrigin.REVIEW_COS, EVehicleCreationOrigin.MERCEDES_AUSTRIA, EVehicleCreationOrigin.SELF_REVIEWED];

const vehicleConditionConfig: Record<string, { headerLabel: string; text: string; kind: string; icon: Icon; description: string; imageUrl: string }> = {
  noDamageSelfInspection: {
    headerLabel: 'vehicle.birdview-icons-description.no-vehicle-damage',
    kind: 'neutral',
    icon: 'info-outline',
    text: 'vehicle.birdview-icons-description.no-damage-self-inspection',
    description: 'vehicle.birdview-icons-description.no-damage-self-inspection-description',
    imageUrl: '/inventory/adv/assets/vehicle-damage-check.png',
  },
  noDamageSafeOrigin: {
    headerLabel: 'vehicle.birdview-icons-description.no-vehicle-damage',
    kind: 'success',
    icon: 'check-outline',
    text: 'vehicle.birdview-icons-description.no-damage',
    description: 'vehicle.birdview-icons-description.no-damage-description',
    imageUrl: '/inventory/adv/assets/vehicle-damage-check.png',
  },
  mayHaveDamages: {
    headerLabel: 'general.vehicle-damages',
    kind: 'highlight',
    icon: 'info-outline',
    text: 'vehicle.birdview-icons-description.may-have-damages',
    description: 'vehicle.birdview-icons-description.may-have-damages-description',
    imageUrl: '/inventory/adv/assets/vehicle-damage-warning.png',
  },
};

@Component({
  standalone: true,
  selector: 'app-no-damage-card',
  templateUrl: './no-damage-card.component.html',
  styleUrls: ['./no-damage-card.component.scss'],
  imports: [EnzoComponentsModule, TranslateModule, NgOptimizedImage, VehicleOriginReportButtonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoDamagesCardComponent {
  public auction = input.required<IAuction>();
  public EVehicleCreationOrigin = EVehicleCreationOrigin;

  public conditionConfig = computed(() => {
    const auctionOrigin = this.auction().associatedVehicle.origin;

    if (auctionOrigin === EVehicleCreationOrigin.SELF_REVIEWED) {
      return vehicleConditionConfig.noDamageSelfInspection;
    }
    if (safeOrigins.includes(auctionOrigin)) {
      return vehicleConditionConfig.noDamageSafeOrigin;
    }
    return vehicleConditionConfig.mayHaveDamages;
  });
}
