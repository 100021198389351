<ng-container *ngIf="auction; else loading">
  <app-general-info [auctionBuyer]="auction" (auctionRefresh)="refresh()">
    <ng-container bid-info>
      <ng-container *ngIf="!(auction | isInstantPurchaseActive); else instantPurchaseButton">
        <app-highest-bid-info [auction]="auction"></app-highest-bid-info>
        <app-bidding-actions
          *ngIf="auction | isAuctionRunning"
          [auction]="auction"
          [currentPrebookedServices]="currentPrebookedServices"
          [buyerUser]="buyerUser"
          #footerTrigger
          data-pf="bidding-actions"></app-bidding-actions>
        <app-voucher-selection [auction]="auction" (onSelectVoucher)="onSelectVoucher($event)"></app-voucher-selection>
        @if (auction.isInternalFulfillment) {
          <app-auction-bnpl-approval-chance [auction]="auction" />
        }
        <app-prebooked-services
          [auction]="auction"
          [currentPrebookedServices]="currentPrebookedServices"
          (serviceSelectionChange)="onPrebookedServiceSelectionChange($event)"></app-prebooked-services>
        <app-fee-info [auction]="auction" [currentPrebookedServices]="currentPrebookedServices" [showPrebookedServicesFees]="false"></app-fee-info>
        <app-invoice-download *ngIf="auction.urlToInvoice" [auction]="auction"></app-invoice-download>
      </ng-container>
      <div *ngIf="auction.advertisementHtmlContent" [innerHtml]="auction.advertisementHtmlContent"></div>
      <app-payment-control *ngIf="(auction | isAuctionWaitingForPayment) && auction.amIHighestBidder" [auction]="auction"></app-payment-control>
      <app-transportation-button *ngIf="(auction | isAuctionSuccessful) && auction.amIHighestBidder" [auction]="auction" (requestAuctionUpdate)="refresh()">
      </app-transportation-button>
      <span class="horizontal-divider"></span>
      <app-auction-transportation-info-with-modal
        *ngIf="(auction | isAuctionWaitingForPickup) || (auction | isAuctionFinished)"
        [auction]="auction"></app-auction-transportation-info-with-modal>
      <button
        *ngIf="isGuaranteeBookButtonVisible && (auction | isAuctionWaitingForPayment)"
        mat-flat-button
        color="primary"
        class="book-guarantee-button"
        (click)="openGuaranteeBookingDialog()">
        <img src="/assets/logo/cos-guarantee/dark-shield-icon.svg" class="guarantee-shield-icon" />
        {{ 'auction.guarantee.book-guarantee' | translate }}
      </button>
      <button mat-stroked-button *ngIf="auction.associatedVehicle.urlToRegistrationDocument" class="registration-document" (click)="openRegistrationDocument()">
        {{ 'vehicle.registration-document' | translate }}
      </button>
    </ng-container>
  </app-general-info>
  <app-bid-info-footer
    [class.hidden]="!showFooter()"
    [auction]="auction"
    [buyerUser]="buyerUser"
    [currentPrebookedServices]="currentPrebookedServices"
    (auctionRefresh)="refresh()" />
</ng-container>

<ng-template #loading>
  <app-auction-detail-skeleton></app-auction-detail-skeleton>
</ng-template>

<ng-template #instantPurchaseButton>
  <app-instant-purchase-button [currentPrebookedServices]="currentPrebookedServices" [auction]="auction" #footerTrigger></app-instant-purchase-button>
</ng-template>
