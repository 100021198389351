import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IAuction } from '@caronsale/cos-models';
import { EVehicleCreationOrigin } from '@caronsale/cos-vehicle-models';
import { ProductAnalyticsService } from '@cosCoreServices/product-analytics/product-analytics.service';

@Component({
  selector: 'app-vehicle-condition-data',
  templateUrl: './vehicle-condition-data.component.html',
  styleUrls: ['./vehicle-condition-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleConditionDataComponent {
  @Input() public auction: IAuction;

  public vehicleCreationOrigin = EVehicleCreationOrigin;
  public privateSellerComplaintTermsUrl: string;

  public constructor(private productAnalyticsService: ProductAnalyticsService) {
    this.productAnalyticsService
      .getFeatureFlag('inv-private-seller-complaint-terms', { value: null, payload: { url: null } })
      .pipe(takeUntilDestroyed())
      .subscribe(flag => (this.privateSellerComplaintTermsUrl = flag.payload.url));
  }
}
