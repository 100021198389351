import { TranslateService } from '@ngx-translate/core';

export type EnzoVariant = 'normal' | 'danger' | 'highlight';
export type EnzoDialogType = 'leftRight' | 'leftRightCancel' | 'rightCancel' | 'confirmReject' | 'confirmCancel' | 'yesNo' | 'yesNoCancel' | 'ok' | 'okCancel';

export const DEFAULT_DIALOG_VARIANT: EnzoVariant = 'normal';
export const DEFAULT_DIALOG_TYPE: EnzoDialogType = 'leftRightCancel';

export interface IDictionary {
  [key: string]: string | (() => string) | IDictionary;
}

export interface IEnzoDialogBaseParams {
  dataCyBase?: string;
  showLoadingIndicator?: boolean;
}

export abstract class EnzoDialogBase {
  public abstract footerButtonBaseKey: string;
  public enzoDialogVariant: EnzoVariant = DEFAULT_DIALOG_VARIANT;
  public enzoDialogType: EnzoDialogType = DEFAULT_DIALOG_TYPE;

  public isTranslationKeyDefined(translateService: TranslateService, key: string): boolean {
    const dictionary: IDictionary | string = translateService.translations[translateService.defaultLang];
    const value = key.split('.').reduce((remainingDictionary, pathPart) => remainingDictionary?.[pathPart], dictionary);
    return !!value;
  }
}
