import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentControlComponent } from './payment-control.component';
import { BaseModule } from '@cosCoreComponentsGeneral/base.module';
import { AuctionCrmPipesModule, AuctionPipesModule } from '@caronsale/frontend-pipes';
import { AdditionalTaxInfoModule } from '@cos/partials/additional-tax-info/additional-tax-info.module';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { BuyerVehiclePaymentDialogComponent } from '@cos/components/buyer/buyer-vehicle-payment-dialog/buyer-vehicle-payment-dialog.component';

@NgModule({
  declarations: [PaymentControlComponent],
  exports: [PaymentControlComponent],
  imports: [
    CommonModule,
    BaseModule,
    AuctionCrmPipesModule,
    AuctionPipesModule,
    BuyerVehiclePaymentDialogComponent,
    AdditionalTaxInfoModule,
    EnzoComponentsModule,
  ],
})
export class PaymentControlModule {}
