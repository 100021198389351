/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 9
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'mpwstaging';

export const ApiKey: Record<Environment, string> = {
  mpwstaging: '',
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '9',
    branch: 'main',
    source: 'web',
    versionId: 'e9db773e-8c06-4bc7-ad34-e70a207dadf2',
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0',
    },
  },
  serverZone: amplitude.Types.ServerZone.EU,
};

export interface LoadOptionsBase {
  disabled?: boolean;
}

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions } };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  'Active look and feel'?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  Permissions?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Pre-registered, Registered |
   */
  'Registration state'?: 'Pre-registered' | 'Registered';
  /**
   * Buyer, seller, buyer/seller or seller subordinate
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  Roles?: string[];
}

export interface AuctionDetailsViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recommended auctions, On watchlist, Recent auctions, High-value vehicles, Electric vehicles, Private vehicles, Instant purchase |
   */
  'Auction category'?:
    | 'Recommended auctions'
    | 'On watchlist'
    | 'Recent auctions'
    | 'High-value vehicles'
    | 'Electric vehicles'
    | 'Private vehicles'
    | 'Instant purchase';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Cockpit, Overview, Room |
   */
  'Auction origin': 'Cockpit' | 'Overview' | 'Room';
  'Auction uuid': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Default, Power |
   */
  'List type'?: 'Default' | 'Power';
  'Room uuid'?: string;
}

export interface BidPlacedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Session Replay ID'?: string;
  'Auction end time': string;
  'Auction uuid': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | List view, Details view, Details view footer |
   */
  'Bid source': 'List view' | 'Details view' | 'Details view footer';
  'Bid timestamp': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Bidding agent, Bid by increment, Bid by value |
   */
  'Bid type': 'Bidding agent' | 'Bid by increment' | 'Bid by value';
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'Bid value': number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Default, Power |
   */
  'List type'?: 'Default' | 'Power';
}

export interface BidProhibitedProperties {
  'Auction uuid': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Bid too high |
   */
  'Bid prohibited reason': 'Bid too high';
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | List view, Details view, Details view footer |
   */
  'Bid source': 'List view' | 'Details view' | 'Details view footer';
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'Bid value': number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'Max bid value'?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'Min ask price': number;
}

export interface ComplaintFormStartedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Session Replay ID'?: string;
}

export interface CounterofferSubmittedProperties {
  'Auction uuid': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'Counteroffer value': number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'Fair value': number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  Round: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Buyer, Seller |
   */
  'User role': 'Buyer' | 'Seller';
}

export interface FiltersAppliedProperties {
  'Filter name': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Cockpit, Overview, Room |
   */
  'Filter origin': 'Cockpit' | 'Overview' | 'Room';
}

export interface InstantlyPurchasedProperties {
  'Auction uuid': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'Instant purchase price': number;
}

export interface PaymentOverviewInvoiceViewedProperties {
  'Invoice reference': string;
}

export interface PaymentOverviewPaginationClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  'Page number': number;
}

export interface PaymentOverviewPaymentRequestViewedProperties {
  'Offer ID': string;
}

export interface PaymentOverviewTabClickedProperties {
  'Tab name': string;
}

export interface SessionStartProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  '[Amplitude] Session Replay ID'?: string;
}

export interface WatchlistAddedProperties {
  'Auction end time': string;
  'Auction uuid': string;
  Timestamp: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | List view, Detail view, Detail view footer |
   */
  'Watchlist type': 'List view' | 'Detail view' | 'Detail view footer';
}

export interface WithdrawalProperties {
  'Auction uuid': string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  Round: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Buyer, Seller |
   */
  'User role': 'Buyer' | 'Seller';
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(public event_properties?: IdentifyProperties) {
    this.event_properties = event_properties;
  }
}

export class AuctionDetailsViewed implements BaseEvent {
  event_type = 'Auction Details Viewed';

  constructor(public event_properties: AuctionDetailsViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class BidPlaced implements BaseEvent {
  event_type = 'Bid placed';

  constructor(public event_properties: BidPlacedProperties) {
    this.event_properties = event_properties;
  }
}

export class BidProhibited implements BaseEvent {
  event_type = 'Bid prohibited';

  constructor(public event_properties: BidProhibitedProperties) {
    this.event_properties = event_properties;
  }
}

export class ComplaintFormStarted implements BaseEvent {
  event_type = 'Complaint form started';

  constructor(public event_properties?: ComplaintFormStartedProperties) {
    this.event_properties = event_properties;
  }
}

export class CounterofferSubmitted implements BaseEvent {
  event_type = 'Counteroffer submitted';

  constructor(public event_properties: CounterofferSubmittedProperties) {
    this.event_properties = event_properties;
  }
}

export class FiltersApplied implements BaseEvent {
  event_type = 'Filters applied';

  constructor(public event_properties: FiltersAppliedProperties) {
    this.event_properties = event_properties;
  }
}

export class InstantlyPurchased implements BaseEvent {
  event_type = 'Instantly purchased';

  constructor(public event_properties: InstantlyPurchasedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentOverviewInvoiceViewed implements BaseEvent {
  event_type = 'Payment overview invoice viewed';

  constructor(public event_properties: PaymentOverviewInvoiceViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentOverviewPaginationClicked implements BaseEvent {
  event_type = 'Payment overview pagination clicked';

  constructor(public event_properties: PaymentOverviewPaginationClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentOverviewPaymentRequestViewed implements BaseEvent {
  event_type = 'Payment overview payment request viewed';

  constructor(public event_properties: PaymentOverviewPaymentRequestViewedProperties) {
    this.event_properties = event_properties;
  }
}

export class PaymentOverviewTabClicked implements BaseEvent {
  event_type = 'Payment overview tab clicked';

  constructor(public event_properties: PaymentOverviewTabClickedProperties) {
    this.event_properties = event_properties;
  }
}

export class SessionEnd implements BaseEvent {
  event_type = 'session_end';
}

export class SessionStart implements BaseEvent {
  event_type = 'session_start';

  constructor(public event_properties?: SessionStartProperties) {
    this.event_properties = event_properties;
  }
}

export class WatchlistAdded implements BaseEvent {
  event_type = 'Watchlist added';

  constructor(public event_properties: WatchlistAddedProperties) {
    this.event_properties = event_properties;
  }
}

export class Withdrawal implements BaseEvent {
  event_type = 'Withdrawal';

  constructor(public event_properties: WithdrawalProperties) {
    this.event_properties = event_properties;
  }
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Auction Details Viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Auction%20Details%20Viewed)
   *
   * Clicking on a auction preview from different places in the platform. Event to track when users view details of an auction listing
   *
   * @param properties The event's properties (e.g. Auction category)
   * @param options Amplitude event options.
   */
  auctionDetailsViewed(
    properties: AuctionDetailsViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuctionDetailsViewed(properties), options);
  }

  /**
   * Bid placed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Bid%20placed)
   *
   * Event indicating that a user has placed a bid on a car listing
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  bidPlaced(
    properties: BidPlacedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BidPlaced(properties), options);
  }

  /**
   * Bid prohibited
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Bid%20prohibited)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Auction uuid)
   * @param options Amplitude event options.
   */
  bidProhibited(
    properties: BidProhibitedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BidProhibited(properties), options);
  }

  /**
   * Complaint form started
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Complaint%20form%20started)
   *
   * Event to track when a user initiates the process of submitting a complaint form.
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  complaintFormStarted(
    properties?: ComplaintFormStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ComplaintFormStarted(properties), options);
  }

  /**
   * Counteroffer submitted
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Counteroffer%20submitted)
   *
   * Event to track when a counteroffer is submitted by a user during a digital renegotiation
   *
   * @param properties The event's properties (e.g. Auction uuid)
   * @param options Amplitude event options.
   */
  counterofferSubmitted(
    properties: CounterofferSubmittedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CounterofferSubmitted(properties), options);
  }

  /**
   * Filters applied
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Filters%20applied)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Filter name)
   * @param options Amplitude event options.
   */
  filtersApplied(
    properties: FiltersAppliedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FiltersApplied(properties), options);
  }

  /**
   * Instantly purchased
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Instantly%20purchased)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. Auction uuid)
   * @param options Amplitude event options.
   */
  instantlyPurchased(
    properties: InstantlyPurchasedProperties,
    options?: EventOptions,
  ) {
    return this.track(new InstantlyPurchased(properties), options);
  }

  /**
   * Payment overview invoice viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Payment%20overview%20invoice%20viewed)
   *
   * Owner: Jan Amend
   *
   * @param properties The event's properties (e.g. Invoice reference)
   * @param options Amplitude event options.
   */
  paymentOverviewInvoiceViewed(
    properties: PaymentOverviewInvoiceViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentOverviewInvoiceViewed(properties), options);
  }

  /**
   * Payment overview pagination clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Payment%20overview%20pagination%20clicked)
   *
   * Owner: Jan Amend
   *
   * @param properties The event's properties (e.g. Page number)
   * @param options Amplitude event options.
   */
  paymentOverviewPaginationClicked(
    properties: PaymentOverviewPaginationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentOverviewPaginationClicked(properties), options);
  }

  /**
   * Payment overview payment request viewed
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Payment%20overview%20payment%20request%20viewed)
   *
   * Owner: Jan Amend
   *
   * @param properties The event's properties (e.g. Offer ID)
   * @param options Amplitude event options.
   */
  paymentOverviewPaymentRequestViewed(
    properties: PaymentOverviewPaymentRequestViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentOverviewPaymentRequestViewed(properties), options);
  }

  /**
   * Payment overview tab clicked
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Payment%20overview%20tab%20clicked)
   *
   * Owner: Jan Amend
   *
   * @param properties The event's properties (e.g. Tab name)
   * @param options Amplitude event options.
   */
  paymentOverviewTabClicked(
    properties: PaymentOverviewTabClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PaymentOverviewTabClicked(properties), options);
  }

  /**
   * session_end
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/session_end)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  sessionEnd(
    options?: EventOptions,
  ) {
    return this.track(new SessionEnd(), options);
  }

  /**
   * session_start
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/session_start)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. [Amplitude] Session Replay ID)
   * @param options Amplitude event options.
   */
  sessionStart(
    properties?: SessionStartProperties,
    options?: EventOptions,
  ) {
    return this.track(new SessionStart(properties), options);
  }

  /**
   * Watchlist added
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Watchlist%20added)
   *
   * Event to track when a user adds a vehicle to their watchlist
   *
   * @param properties The event's properties (e.g. Auction end time)
   * @param options Amplitude event options.
   */
  watchlistAdded(
    properties: WatchlistAddedProperties,
    options?: EventOptions,
  ) {
    return this.track(new WatchlistAdded(properties), options);
  }

  /**
   * Withdrawal
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/caronsale/MPW%20-%20Staging/events/main/latest/Withdrawal)
   *
   * Event to track when a user initiates a withdrawal from digital renegotiatioj
   *
   * @param properties The event's properties (e.g. Auction uuid)
   * @param options Amplitude event options.
   */
  withdrawal(
    properties: WithdrawalProperties,
    options?: EventOptions,
  ) {
    return this.track(new Withdrawal(properties), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
