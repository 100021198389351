import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, catchError, EMPTY, filter, map, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppEnvironment, IAppEnvironment } from '@cosCoreEnvironments/IAppEnvironment';
import { CosCoreClient } from '@caronsale/frontend-services';
import { ELanguageCode } from '@caronsale/cos-models';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface IPhraseConfig {
  phraseProjectId: string;
  phraseAccessToken: string;
  phraseEndpointUrl: string;
  locallyStoredDefaultLanguage: string;
  languageFallback?: string;
  hiddenLanguagesPhraseVariableName?: string;
  sourceBranchName?: string;
}

const LANGUAGE_FALLBACK_DEFAULT = ELanguageCode.EN;

function isELanguageCode(value: any): value is ELanguageCode {
  return Object.values(ELanguageCode).includes(value);
}

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private languageChangedSubject = new BehaviorSubject<ELanguageCode>(
    // Todo: use null as initial value and add filter(Boolean) to the exported observable.
    //  But first make sure that every component that uses this can actually wait until the .json file is loaded
    isELanguageCode(this.appEnvironment.phraseConfig.locallyStoredDefaultLanguage)
      ? this.appEnvironment.phraseConfig.locallyStoredDefaultLanguage
      : LANGUAGE_FALLBACK_DEFAULT,
  );

  public languageChanged$: Observable<ELanguageCode> = this.languageChangedSubject.asObservable();
  // Todo: delete (provided for compatibillity only)
  public languageChanged: Observable<string> = this.languageChangedSubject.asObservable();

  private availableLanguages: ELanguageCode[] = [
    ELanguageCode.BG,
    ELanguageCode.CS,
    ELanguageCode.DA,
    ELanguageCode.DE,
    ELanguageCode.EN,
    ELanguageCode.ES,
    ELanguageCode.FR,
    ELanguageCode.HU,
    ELanguageCode.IT,
    ELanguageCode.LT,
    ELanguageCode.NL,
    ELanguageCode.PL,
    ELanguageCode.PT,
    ELanguageCode.RO,
    ELanguageCode.SK,
    ELanguageCode.SV,
    ELanguageCode.TR,
    // available in Phrase but "hidden"
    // ELanguageCode.SL,
  ];

  public availableLanguages$: Observable<ELanguageCode[]> = new BehaviorSubject<ELanguageCode[]>(this.availableLanguages).asObservable();

  public constructor(
    private translateService: TranslateService,
    @Inject(AppEnvironment) private appEnvironment: IAppEnvironment,
    private cosCoreClient: CosCoreClient,
  ) {
    this.translateService.onLangChange
      .pipe(
        map(langChangeEvent => (isELanguageCode(langChangeEvent.lang) ? langChangeEvent.lang : null)),
        filter(Boolean),
        takeUntilDestroyed(),
      )
      .subscribe(this.languageChangedSubject);

    const userSelectedLanguage = localStorage.getItem('userSelectedLanguage');

    // the order of importance is:
    // 1. language chosen by user
    // 2. content language set via browser
    // 3. fallback language
    const lang =
      (isELanguageCode(userSelectedLanguage) && this.availableLanguages.includes(userSelectedLanguage) ? userSelectedLanguage : null) ||
      this.getBrowserLanguages().find(lang => this.availableLanguages.includes(lang)) ||
      appEnvironment.phraseConfig.languageFallback ||
      LANGUAGE_FALLBACK_DEFAULT;

    this.translateService.use(lang); // will emit onLangChange when the .json file is downloaded
  }

  private getBrowserLanguages(): ELanguageCode[] {
    const browserLocales = navigator.languages || [navigator.language];

    return browserLocales
      .map(locale => locale.trim().split(/-|_/)[0])
      .map(lang => (isELanguageCode(lang) ? lang : null))
      .filter(Boolean);
  }

  // Todo: delete (provided for compatibillity only), please use availableLanguages$ directly.
  public get languages(): Observable<any[]> {
    return this.availableLanguages$;
  }

  public selectLanguage(langCode: string): void {
    if (isELanguageCode(langCode) && this.availableLanguages.includes(langCode)) {
      localStorage.setItem('userSelectedLanguage', langCode);
      this.translateService.use(langCode);

      // use catchError to avoid a sentry error report when not logged in initially
      this.cosCoreClient
        .setLanguage(langCode)
        .pipe(catchError(() => EMPTY))
        .subscribe();
    }
  }
}
