import { EAuctionState, ECountryCode, IAuction, IAuctionFieldConfirmationStatusMap, ILocation, ModelUtils, Time, Validation } from '@caronsale/cos-models';
import {
  EFuelType,
  EVehicleCreationOrigin,
  EVehicleFullServiceHistoryType,
  EVehicleInteriorDamagePart,
  EVehiclePart,
  EVehicleReadyToDriveStatus,
  EVehicleServiceHistoryAvailability,
  IVehicle,
  IVehicleFieldConfirmationStatusMap,
  IVehicleValuation,
} from '@caronsale/cos-vehicle-models';
import { enumToArray } from './type-script-utils';

export interface IVehicleDamageUIElement {
  cssClass: string;
  locations: (EVehiclePart | EVehicleInteriorDamagePart)[];

  /**
   * When number of locations is too big, only some of them can be defined in this list.
   */
  tooltipLocations?: (EVehiclePart | EVehicleInteriorDamagePart)[];
}

export enum EUnknownValues {
  DATE = '00/0000',
  PRE_OWNERS = 99,
}

export class CarOnSaleUtils {
  public static readonly VEHICLE_ORIGINS: EVehicleCreationOrigin[] = [
    EVehicleCreationOrigin.UNKNOWN,
    EVehicleCreationOrigin.MANUAL_WEB,
    EVehicleCreationOrigin.MANUAL_APP,
    EVehicleCreationOrigin.REVIEW_UNKNOWN,
    EVehicleCreationOrigin.SELF_REVIEWED,
    EVehicleCreationOrigin.REVIEW_COS,
    EVehicleCreationOrigin.MERCEDES_AUSTRIA,
    EVehicleCreationOrigin.REVIEW_DEKRA,
    EVehicleCreationOrigin.REVIEW_TUV_SUD,
    EVehicleCreationOrigin.REVIEW_TUV_NORD,
    EVehicleCreationOrigin.REVIEW_TUV_RHEINLAND,
    EVehicleCreationOrigin.REVIEW_GTU,
    EVehicleCreationOrigin.REVIEW_HUSGES,
    EVehicleCreationOrigin.REVIEW_KUS,
    EVehicleCreationOrigin.REVIEW_ALPHA_CONTROLLER,
    EVehicleCreationOrigin.REVIEW_VFKS,
  ];
  public static readonly READY_TO_DRIVE = [EVehicleReadyToDriveStatus.YES, EVehicleReadyToDriveStatus.NO];
  public static readonly SERVICE_HISTORY_AVAILABILITY = enumToArray<EVehicleServiceHistoryAvailability>(EVehicleServiceHistoryAvailability);
  public static readonly FULL_SERVICE_HISTORY = enumToArray<EVehicleFullServiceHistoryType>(EVehicleFullServiceHistoryType);
  public static readonly FUEL_TYPES = enumToArray<EFuelType>(EFuelType);
  public static readonly AVAILABLE_COUNTRIES: ECountryCode[] = [ECountryCode.DE, ECountryCode.AT, ECountryCode.NL, ECountryCode.FR];

  public static readonly CONVERSION_RATIOS = {
    KW_TO_HP: 1.35962,
    HP_TO_KW: 0.73549875,

    MI_TO_KM: 1.609344,
    KM_TO_MI: 0.62137119,
  };

  public static EZS = Time.getYearsFromXUntilToday(1886);

  public static MILEAGE_MIN = 10000;
  public static MILEAGE_MAX = 380000;

  public static PRICES_MIN = 10000;
  public static PRICES_MAX = 300000;

  public static readonly PRICES = CarOnSaleUtils.generateNumberSequence(this.PRICES_MIN, this.PRICES_MAX, [
    [80000, 10000],
    [this.PRICES_MAX, 20000],
  ]);
  public static readonly MILEAGES = CarOnSaleUtils.generateMileageSequence();

  public static generateMileageSequence(
    start: number = CarOnSaleUtils.MILEAGE_MIN,
    stop: number = CarOnSaleUtils.MILEAGE_MAX,
    steps: [threshold: number, stepSize: number][] = [
      [80000, 10000],
      [CarOnSaleUtils.MILEAGE_MAX, 20000],
    ],
  ) {
    return CarOnSaleUtils.generateNumberSequence(start, stop, steps);
  }

  /**
   * Generates a sequence of numbers based on specified parameters.
   * @param {number} start - The starting mileage.
   * @param {number} stop - The maximum mileage to generate.
   * @param {[threshold: number, stepSize: number][]} steps - An array of threshold-stepSize pairs defining additional steps in the sequence.
   *   Each inner array specifies a threshold value and a step size.
   *   The function will increment the sequence by the step size until it reaches the threshold value or the stop value, whichever comes first.
   * @returns {number[]} An array of generated numbers in the specified sequence.
   */
  public static generateNumberSequence(start: number, stop: number, steps: [threshold: number, stepSize: number][]): number[] {
    const numbers = [start];
    let currentNumber = start;

    for (const [threshold, stepSize] of steps) {
      let nextNumber = currentNumber + stepSize;
      while (nextNumber <= threshold && nextNumber <= stop) {
        numbers.push(nextNumber);
        currentNumber = nextNumber;
        nextNumber += stepSize;
      }
    }

    return numbers;
  }

  public static getVehicleDamageUIEntries(): IVehicleDamageUIElement[] {
    return [
      {
        cssClass: 'headlight-front-left',
        locations: [EVehiclePart.HEADLIGHT_FRONT_LEFT],
      },
      {
        cssClass: 'headlight-front-right',
        locations: [EVehiclePart.HEADLIGHT_FRONT_RIGHT],
      },
      {
        cssClass: 'roof',
        locations: [
          EVehiclePart.ROOF,
          EVehiclePart.INTERIOR,
          EVehiclePart.OTHER,
          EVehiclePart.CONVERTIBLE_TOP,
          ...(ModelUtils.getEnumValues(EVehicleInteriorDamagePart) as number[]),
        ],
        tooltipLocations: [
          //
          EVehiclePart.ROOF,
          EVehiclePart.INTERIOR,
          EVehiclePart.CONVERTIBLE_TOP,
        ],
      },
      {
        cssClass: 'door-front-left',
        locations: [EVehiclePart.DOOR_FRONT_LEFT, EVehiclePart.SILL_LEFT],
      },
      {
        cssClass: 'door-front-right',
        locations: [EVehiclePart.DOOR_FRONT_RIGHT, EVehiclePart.SILL_RIGHT],
      },
      {
        cssClass: 'door-rear-left',
        locations: [EVehiclePart.DOOR_REAR_LEFT],
      },
      {
        cssClass: 'door-rear-right',
        locations: [EVehiclePart.DOOR_REAR_RIGHT],
      },
      {
        cssClass: 'bumper-front',
        locations: [EVehiclePart.BUMPER_FRONT],
      },
      {
        cssClass: 'bumper-rear',
        locations: [EVehiclePart.BUMPER_REAR, EVehiclePart.TRUNK],
      },
      {
        cssClass: 'front-wheel-left',
        locations: [EVehiclePart.FRONT_WHEEL_LEFT, EVehiclePart.FENDER_FRONT_LEFT],
      },
      {
        cssClass: 'front-wheel-right',
        locations: [EVehiclePart.FRONT_WHEEL_RIGHT, EVehiclePart.FENDER_FRONT_RIGHT],
      },
      {
        cssClass: 'rear-wheel-left',
        locations: [EVehiclePart.REAR_WHEEL_LEFT, EVehiclePart.SIDE_PART_REAR_LEFT],
      },
      {
        cssClass: 'rear-wheel-right',
        locations: [EVehiclePart.REAR_WHEEL_RIGHT, EVehiclePart.SIDE_PART_REAR_RIGHT],
      },
      {
        cssClass: 'hood',
        locations: [EVehiclePart.HOOD, EVehiclePart.GENERAL],
      },
      {
        cssClass: 'rear-window',
        locations: [EVehiclePart.REAR_WINDOW, EVehiclePart.TAILGATE],
      },
      {
        cssClass: 'windshield',
        locations: [EVehiclePart.FRONT_WINDOW],
      },
      {
        cssClass: 'headlight-rear-left',
        locations: [EVehiclePart.HEADLIGHT_REAR_LEFT],
      },
      {
        cssClass: 'headlight-rear-right',
        locations: [EVehiclePart.HEADLIGHT_REAR_RIGHT],
      },
    ];
  }

  public static getVehicleEnginePowerInKW(enginePowerInHp: number): number {
    return Math.round(enginePowerInHp * this.CONVERSION_RATIOS.HP_TO_KW);
  }

  /**
   * Set default values to allow create drafted auction.
   *
   * @param auctionToCreate
   */
  public static setDefaultDraftAuctionValues(auctionToCreate: IAuction): void {
    auctionToCreate.minimumRequiredAsk = -1;
    auctionToCreate.endingTime = Time.getDateXDaysFromNow(3);

    auctionToCreate.state = EAuctionState.DRAFTED;
  }

  /**
   * Fill vehicle data extracted from a COS condition report, DAT and price valuation.
   *
   * @param vehicleToCreate
   * @param parsedVehicle
   * @param valuationForVehicle
   */
  public static setVehicleValues(vehicleToCreate: IVehicle, parsedVehicle: IVehicle, valuationForVehicle: IVehicleValuation): void {
    ModelUtils.setVehicleData(vehicleToCreate, parsedVehicle);
    ModelUtils.setVehicleDataOrUnknown(vehicleToCreate, parsedVehicle);

    vehicleToCreate.uuid = parsedVehicle.uuid;
    vehicleToCreate.inspectionUuid = CarOnSaleUtils.getValueOrDefault(parsedVehicle.inspectionUuid, null);

    vehicleToCreate.readyToDrive = CarOnSaleUtils.getValueOrDefault(parsedVehicle.readyToDrive, EVehicleReadyToDriveStatus.NO);

    vehicleToCreate.origin = CarOnSaleUtils.getValueOrDefault(parsedVehicle.origin, EVehicleCreationOrigin.REVIEW_UNKNOWN);

    vehicleToCreate.countryOfLastRegistration = CarOnSaleUtils.getValueOrDefault(parsedVehicle.countryOfLastRegistration, ECountryCode.DE);

    vehicleToCreate.isCocDocumentAvailable = CarOnSaleUtils.getValueOrDefault(parsedVehicle.isCocDocumentAvailable, null);
    vehicleToCreate.huReportExists = CarOnSaleUtils.getValueOrDefault(parsedVehicle.huReportExists, false);

    vehicleToCreate.additionalInfo = CarOnSaleUtils.getValueOrDefault(parsedVehicle.additionalInfo, null);
    vehicleToCreate.hadAccident = CarOnSaleUtils.getValueOrDefault(parsedVehicle.hadAccident, true);
    vehicleToCreate.accidentDescription = CarOnSaleUtils.getValueOrDefault(parsedVehicle.accidentDescription, 'Siehe Anhang');
    vehicleToCreate.hasDamages = CarOnSaleUtils.getValueOrDefault(parsedVehicle.hasDamages, true);
    vehicleToCreate.damagesDescription = CarOnSaleUtils.getValueOrDefault(parsedVehicle.damagesDescription, 'Siehe Anhang');

    // Set valuation:
    vehicleToCreate.estimatedValue = CarOnSaleUtils.getValueOrDefault(valuationForVehicle && valuationForVehicle.salesPrice, vehicleToCreate.estimatedValue);

    // set placeholders if needed
    vehicleToCreate.make = CarOnSaleUtils.getValueOrDefault(vehicleToCreate.make, 'placeholder');
    vehicleToCreate.model = CarOnSaleUtils.getValueOrDefault(vehicleToCreate.model, 'placeholder');
  }

  /**
   * Fill vehicle with values extracted from the 3rd-party condition report.
   *
   * @param vehicleToCreate
   * @param parsedVehicle
   */
  public static setVehicleValuesFromPdfReport(vehicleToCreate: IVehicle, parsedVehicle: IVehicle): void {
    vehicleToCreate.vin = parsedVehicle.vin;
    vehicleToCreate.ez = parsedVehicle.ez || '01/1900';
    vehicleToCreate.lastHu = parsedVehicle.lastHu;
    vehicleToCreate.mileageInKm = CarOnSaleUtils.getValueOrDefault(parsedVehicle.mileageInKm, -1);

    vehicleToCreate.fullServiceHistoryType = CarOnSaleUtils.getValueOrDefault(parsedVehicle.fullServiceHistoryType, vehicleToCreate.fullServiceHistoryType);
    vehicleToCreate.isReimportedVehicle = CarOnSaleUtils.getValueOrDefault(parsedVehicle.isReimportedVehicle, vehicleToCreate.isReimportedVehicle);
    vehicleToCreate.numKeys = CarOnSaleUtils.getValueOrDefault(parsedVehicle.numKeys, vehicleToCreate.numKeys);
    vehicleToCreate.numPreOwners = CarOnSaleUtils.getValueOrDefault(parsedVehicle.numPreOwners, vehicleToCreate.numPreOwners);
    vehicleToCreate.lastServiceInspectionDate = parsedVehicle.lastServiceInspectionDate;
    vehicleToCreate.lastServiceInspectionMileage = parsedVehicle.lastServiceInspectionMileage;

    // Add images:
    vehicleToCreate.vehicleImages = parsedVehicle.vehicleImages;

    // Attach vehicle report
    vehicleToCreate.urlToAttachment1 = parsedVehicle.urlToAttachment1;
    vehicleToCreate.urlToAttachment2 = parsedVehicle.urlToAttachment2;
    vehicleToCreate.urlToAttachment3 = parsedVehicle.urlToAttachment3;

    vehicleToCreate.origin = parsedVehicle.origin;

    vehicleToCreate.tires = parsedVehicle.tires;
    vehicleToCreate.paintState = parsedVehicle.paintState;
    vehicleToCreate.damages = parsedVehicle.damages;
    vehicleToCreate.hasDamages = parsedVehicle.hasDamages;
    vehicleToCreate.accidentDescription = parsedVehicle.accidentDescription;
    vehicleToCreate.additionalInfo = parsedVehicle.additionalInfo;
    vehicleToCreate.hadAccident = parsedVehicle.hadAccident;

    if (Validation.isUndefinedOrNull(vehicleToCreate.accidentDescription)) {
      // reset the flag to allow create an auction.
      vehicleToCreate.hadAccident = false;
    }
  }

  public static getValueOrDefault<T>(currentValue: T, defaultValue: T): T {
    return Validation.isUndefinedOrNull(currentValue) ? defaultValue : currentValue;
  }

  public static isFieldConfirmed(
    fieldsConfirmationStatus: IAuctionFieldConfirmationStatusMap | IVehicleFieldConfirmationStatusMap,
    field: keyof (IAuctionFieldConfirmationStatusMap & IVehicleFieldConfirmationStatusMap),
    currentValue: any,
  ): boolean | null {
    if (Validation.isUndefinedNullOrEmptyObject(fieldsConfirmationStatus)) {
      return null;
    }

    if (!fieldsConfirmationStatus[field]) {
      return false;
    }

    const status = fieldsConfirmationStatus[field];

    return !!status.confirmed && status.value === currentValue;
  }

  /**
   * TODO: Remove next release
   * @param auction
   * @param location
   */
  public static setLocationForAuction(auction: IAuction, location: ILocation): void {
    auction.locationAddress = location.addressLine;
    auction.locationZip = location.zipCode;
    auction.locationCity = location.city;
    auction.locationCountryCode = location.countryCode;
  }

  /**
   * Used for the migration purposes, should be removed in scope of INV-1077
   * Copies the vat reportable flag from an auction to a vehicle.
   */
  public static syncVatReportable(auction: IAuction): void {
    auction.associatedVehicle.vatIdReportable = auction.isVATReportable;
  }
}
