<app-expandable-panel [expandedState]="true">
  <div panelTitle class="panel-title">
    <enzo-icon [icon]="'condition'"></enzo-icon>
    <enzo-headline level="4" color="blackish">{{ 'general.vehicle-condition' | translate }}</enzo-headline>
  </div>
  <div panelContent class="panel-content">
    <div class="damages-container">
      <enzo-text
        *ngIf="auction.associatedVehicle.origin === vehicleCreationOrigin.SELF_REVIEWED"
        kind="emphasis-label"
        icon="info-outline"
        icon-size="small"
        class="private-seller-complaint-terms">
        {{ 'vehicle.condition.private-seller-complaint-terms.prefix' | translate }}
        <a [href]="privateSellerComplaintTermsUrl" target="_blank">{{ 'vehicle.condition.private-seller-complaint-terms.link' | translate }}</a>
        {{ 'vehicle.condition.private-seller-complaint-terms.suffix' | translate }}
      </enzo-text>

      <app-vehicle-damages [auction]="auction" />

      <app-tech-state-info [auction]="auction" />

      <app-paint-status-info [auction]="auction" *ngIf="auction.associatedVehicle.origin | isVehicleCreatedFromCosReport" />

      <div *ngIf="auction.associatedVehicle.hadAccident && auction.associatedVehicle.accidentDescription" class="fixed-damage-container">
        <enzo-text kind="body-bold">{{ 'vehicle.repaired-damage' | translate }}</enzo-text>
        <enzo-text color="blackish">{{ auction.associatedVehicle.accidentDescription }}</enzo-text>
      </div>
    </div>

    <app-tires-info [vehicle]="auction.associatedVehicle" />
  </div>
</app-expandable-panel>
