import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { CosCoreClient } from '@caronsale/frontend-services';

@Component({
  selector: 'app-loading-layer',
  templateUrl: './loading-layer.component.html',
  styleUrls: ['./loading-layer.component.scss'],
})
export class LoadingLayerComponent {
  @Input()
  public showLoadingLayerWhen: boolean;

  @Input()
  public loadingHintText: string;

  public constructor(
    private cosCoreClient: CosCoreClient,
    private router: Router,
  ) {}

  public async reset() {
    this.cosCoreClient.resetSessionData();

    this.cosCoreClient.signOff().subscribe(async () => {
      await this.router.navigate(['/login']);
    });
  }
}
