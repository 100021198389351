<div *ngIf="shouldBeDisplayed">
  @if (displayTitle) {
    <enzo-text [class.sub-title]="guaranteeFeatureEnabled" kind="body-bold">{{ 'vehicle.tech-state' | translate }}</enzo-text>
  }

  <ng-container *ngIf="guaranteeFeatureEnabled; else guaranteeNotEnabled">
    <div class="title-guaranteed">
      <enzo-text kind="body-bold">{{ 'prebooked-service.guarantee.cos-check-plus-label' | translate }}</enzo-text>
      <app-cos-check-plus-guarantee-badge></app-cos-check-plus-guarantee-badge>
      <enzo-ghost-button
        variant="highlight"
        [label]="'auction.guarantee.learn-more' | translate"
        (enzoClick)="showLearnMorePopover($event)"></enzo-ghost-button>
    </div>

    <enzo-text kind="label">{{ 'auction.guarantee.covered-subtitle' | translate }}</enzo-text>
    <app-vehicle-details-grid [bgColorOnOdds]="true">
      <div *ngFor="let state of normalParts">
        <enzo-text kind="overline" color="grey-dark">{{ state.part | i18nVehiclePart | translate }}</enzo-text>
        <button class="show-more-button" (click)="showGuaranteePartDetailsPopover($event, state)">{{ 'action.more' | translate }}</button>
      </div>
    </app-vehicle-details-grid>
    <enzo-text kind="caption" [innerHTML]="'auction.guarantee.disclaimer' | translate: { url: guaranteeConditionsUrl }"></enzo-text>

    <ng-container *ngIf="abnormalParts.length">
      <enzo-text kind="body-bold" class="uncovered-sub-title">{{ 'auction.guarantee.uncovered-title' | translate }}</enzo-text>
      <app-vehicle-details-grid [bgColorOnOdds]="true">
        <div *ngFor="let state of abnormalParts">
          <enzo-text kind="overline" color="grey-dark">{{ state.part | i18nVehiclePart | translate }}</enzo-text>
          <div class="inline-text">
            <enzo-text [innerHTML]="state | i18nVehicleTechStateDescription | safeHtml"></enzo-text>
            <enzo-icon *ngIf="state.state === EVehicleTechState.DEFECT" icon="warning" color="orange" size="small"></enzo-icon>
          </div>
        </div>
      </app-vehicle-details-grid>
    </ng-container>
  </ng-container>

  <div class="motor-sound" *ngIf="!!auction.associatedVehicle.urlToMotorSound">
    <enzo-icon icon="car-sound"></enzo-icon>
    <enzo-text kind="body-bold">{{ 'vehicle.motor-sound-label' | translate }}</enzo-text>
    <div class="audio-container">
      <audio [src]="auction.associatedVehicle.urlToMotorSound" controls (play)="trackAudioPlay()" label="audio"></audio>
    </div>
  </div>

  <ng-container *ngIf="wearParts.length">
    <enzo-text kind="body-bold">{{ 'vehicle.wear-parts' | translate }}</enzo-text>
    <app-vehicle-details-grid [bgColorOnOdds]="true">
      <div *ngFor="let state of wearParts">
        <enzo-text kind="overline" color="grey-dark">{{ state.part | i18nVehiclePart | translate }}</enzo-text>
        <enzo-text
          [innerHTML]="state | i18nVehicleTechStateDescription | safeHtml"
          [icon]="state.state === EVehicleTechState.OK ? 'cos-approved' : 'warning'"
          [iconColor]="state.state === EVehicleTechState.OK ? 'green' : 'orange'"
          iconSize="small"></enzo-text>
      </div>
    </app-vehicle-details-grid>
  </ng-container>

  <div *ngIf="otherTechState">
    <enzo-text kind="body-bold">{{ 'vehicle.tech-state-label.other-damages' | translate }}</enzo-text>
    <enzo-text [innerHTML]="otherTechState | i18nVehicleTechStateDescription | safeHtml"></enzo-text>
  </div>
</div>

<ng-template #guaranteeNotEnabled>
  <app-vehicle-details-grid [bgColorOnOdds]="true">
    <div *ngFor="let state of normalParts.concat(abnormalParts)">
      <enzo-text kind="overline" color="grey-dark">{{ state.part | i18nVehiclePart | translate }}</enzo-text>
      <enzo-text
        [innerHTML]="state | i18nVehicleTechStateDescription | safeHtml"
        [icon]="state.state === EVehicleTechState.OK ? 'cos-approved' : 'warning'"
        [iconColor]="state.state === EVehicleTechState.OK ? 'green' : 'orange'"
        iconSize="small">
      </enzo-text>
    </div>
  </app-vehicle-details-grid>
</ng-template>
