import { Component } from '@angular/core';
import { EnzoComponentsModule } from '@caronsale/enzo-angular';
import { ServiceBadgeComponent } from '@cos/partials/service-badge/service-badge.component';

@Component({
  selector: 'app-cos-check-plus-guarantee-badge',
  templateUrl: './cos-check-plus-guarantee-badge.component.html',
  styleUrls: ['./cos-check-plus-guarantee-badge.component.scss'],
  imports: [ServiceBadgeComponent, EnzoComponentsModule],
  standalone: true,
})
export class CosCheckPlusGuaranteeBadgeComponent {}
